import { useCallback, useEffect, useRef } from "react";
import { Drawer } from "@mui/material";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { BillingSidebarState, selectBillingSidebar } from "store/billing-sidebar/slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { styles } from "./styles";

interface Props {
  objectName?: string;
  children: React.ReactNode;
  onClose?: () => void;
  isOpen?: boolean;
}

export const DetailSidebar = (props: Props) => {
  const dispatch = useAppDispatch();
  const classes = styles();
  const prevOpen = useRef(open);
  const stateFunction = (): BillingSidebarState => {
    return useAppSelector(selectBillingSidebar);
  };
  const state: any = stateFunction();
  const sidebarOperations = () => {
    return billingSidebarOperations;
  };

  useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  const handleClose = () => {
    dispatch(sidebarOperations().close());
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
  }, []);

  return (
    <Drawer
      anchor="right"
      open={props.isOpen !== undefined ? props.isOpen : state.isOpen}
      variant="persistent"
      className={classes.sideBarDrawer}
    >
      {props.children}
    </Drawer>
  );
};
