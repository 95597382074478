import { theme } from "extensions/theme";

export const styles = {
  typography: {
    color: theme.palette.grayScale[700],
    fontSize: "14px",
    display: "flex",
    gap: "8px",
  },
  textField: {
    width: "170px",
    "& .MuiInputBase-input": {
      padding: "10px 8px",
    },
  },
};
