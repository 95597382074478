import { formatDateUtil } from "./formatDateUtil";

export const timeRange = (format: string, startTimeStr?: string, endTimeStr?: string): string => {
  if (!startTimeStr || !endTimeStr) {
    return "未設定";
  }

  const startTime = new Date(startTimeStr);
  const endTime = new Date(endTimeStr);
  const resultStartTime = formatDateUtil(new Date(startTime), format);

  // 年、月、日が同じ場合は時刻のみ表示
  if (
    startTime.getFullYear() === endTime.getFullYear() &&
    startTime.getMonth() === endTime.getMonth() &&
    startTime.getDate() === endTime.getDate()
  ) {
    return resultStartTime + "~" + formatDateUtil(endTime, "HH:mm");
  } else {
    // 年が異なる場合は完全なフォーマットを使用
    if (startTime.getFullYear() !== endTime.getFullYear()) {
      return resultStartTime + "~" + formatDateUtil(endTime, format);
    }
    // 年が同じで月日が異なる場合は年を除いたフォーマットを使用
    else {
      const endFormat = format.replace(/yyyy.?/, "");
      return resultStartTime + "~" + formatDateUtil(endTime, endFormat);
    }
  }
};
