import { Popover } from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  tax: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, amount: number) => void;
  anchorEl: HTMLButtonElement | null;
}

export const TaxChangeDialog = (props: Props) => {
  return (
    <Popover
      open={props.isOpen}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ "& .MuiPaper-root": { p: "20px 16px" } }}
    >
      <p style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "16px", marginTop: 0 }}>
        消費税の手動設定
      </p>
      <AmountTextField
        name="tax"
        width="208px"
        value={props.tax}
        onChange={props.onChange}
        blurFunc={props.onBlur}
      />
    </Popover>
  );
};
