import { ElementRef, useEffect, useMemo, useState } from "react";
import HandymanIcon from "@mui/icons-material/Handyman";
import { Box, Collapse, Divider, Typography } from "@mui/material";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { ProjectId } from "data-access/repositories/project/project.dto";
import {
  MaterialCostId,
  MaterialCostIndexResponse,
  MaterialCostUpdateRequest,
} from "features/cost/types/material_cost.dto";
import { MaterialCostCard } from "./card";
import { MaterialCostCreateModal } from "./create-modal";
import { MaterialCostEditModal } from "./edit-modal";
import { styles } from "./styles";
import { AddButton } from "../add_button";
import type { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";

interface Props {
  projectId: ProjectId;
  data: MaterialCostIndexResponse;
  confirmRef: React.RefObject<ElementRef<typeof AsyncConfirmDialog>>;
}
export const MaterialCostList = (props: Props) => {
  const classes = styles();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<{
    isOpen: boolean;
    materialCostId: MaterialCostId;
    name: string;
    body: { amount: number; note: string };
  }>({
    isOpen: false,
    materialCostId: 0 as MaterialCostId,
    name: "",
    body: { amount: 0, note: "" },
  });
  const [state, setState] = useState<MaterialCostIndexResponse>([]);

  const totalExpense: number = useMemo(() => {
    return state.reduce((acc, cur) => acc + cur.amount, 0);
  }, [state]);

  useEffect(() => {
    if (!props.data) return;
    setState(props.data);
  }, [props.data]);

  const handleEditModalOpen = (
    id: MaterialCostId,
    name: string,
    body: MaterialCostUpdateRequest,
  ) => {
    setEditModal({ isOpen: true, materialCostId: id, name, body });
  };

  return (
    <>
      <MaterialCostCreateModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        projectId={props.projectId}
      />
      <MaterialCostEditModal
        isOpen={editModal.isOpen}
        onClose={() =>
          setEditModal({
            isOpen: false,
            materialCostId: 0 as MaterialCostId,
            name: "",
            body: { amount: 0, note: "" },
          })
        }
        projectId={props.projectId}
        materialCostId={editModal.materialCostId}
        name={editModal.name}
        state={editModal.body}
        confirmRef={props.confirmRef}
      />

      <Box
        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div style={{ display: "flex", gap: "8px" }}>
          <Box className={classes.badge}>
            <HandymanIcon fontSize="small" />
            <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>材料費</Typography>
          </Box>
          <Typography fontWeight="bold" color="primary">
            ¥{totalExpense.toLocaleString()}
          </Typography>
        </div>
        <AccordionToggle
          title=""
          isExpanded={isExpanded}
          setIsExpanded={() => setIsExpanded(!isExpanded)}
        />
      </Box>
      <Divider sx={{ my: "12px" }} />
      <Collapse
        in={isExpanded}
        timeout="auto"
        sx={{
          ml: "80px",
          "& .MuiCollapse-wrapperInner": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          },
        }}
      >
        {state.map((materialCost, index) => (
          <MaterialCostCard
            key={index}
            materialCost={materialCost}
            setEdit={(id, name, body) => handleEditModalOpen(id, name, body)}
          />
        ))}
        <AddButton
          onOpen={() => setIsCreateModalOpen(true)}
          entityName="仕入先"
          width="248px"
          height="72px"
        />
      </Collapse>
    </>
  );
};
