import { Box } from "@mui/material";
import { DiscardChangesConfirmDialog } from "components/molecules/discard-changes-confirm-dialog";
import { BillingTable } from "components/organisms/billing-table";
import { BillingTableHeader } from "components/organisms/billing-table-header";
import { DeleteConfirmDialog } from "components/templates/delete-confirm-dialog";
import { Layout } from "components/templates/layout/index";
import { ResponseSnackbar } from "components/templates/response-snackbar";
import { billingTableOperations } from "store/billing-table/operations";
import { selectBillingTable } from "store/billing-table/slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BillingSidebar } from "./sidebar";

export default function Billings() {
  const dispatch = useAppDispatch();
  const billingTableState = useAppSelector(selectBillingTable);

  const handleCloseErrorSnackBar = () => {
    dispatch(billingTableOperations.updateErrorMessage(""));
  };

  return (
    <Layout>
      <ResponseSnackbar
        errorMessage={billingTableState.errorMessage}
        handleCloseError={handleCloseErrorSnackBar}
      />
      <DiscardChangesConfirmDialog />
      <DeleteConfirmDialog />

      <Box>
        <BillingTableHeader />
        <Box sx={{ mt: 3 }}>
          <BillingTable />
        </Box>

        <BillingSidebar />
      </Box>
    </Layout>
  );
}
