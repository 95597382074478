import { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { Client } from "data-access/repositories/client/client.dto";
import {
  ProjectFormReportContentsState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { theme } from "extensions/theme";
import { ReportContentPrintModal } from "features/project/components/sidebar/printable-report-content/modal";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { handleReduxError } from "utils/errorHandler";

type FormValues = {
  requester: string;
  requestedByPersonName: string;
  reportContent: string;
};
interface Props {
  projectId?: ProjectId;
  allState: ProjectFormUpdateRequest;
  reportContentState: ProjectFormReportContentsState;
  setReportContentState: React.Dispatch<React.SetStateAction<ProjectFormReportContentsState>>;
  lastSavedFormValue: React.MutableRefObject<ProjectFormUpdateRequest>;
  selectedClient: Client;
}
export const ProjectSidebarPrintableReportContent = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleUpdate = async (currentValue: string | number | null, target: keyof FormValues) => {
    if (!props.projectId) return;
    if (currentValue == props.lastSavedFormValue.current[target]) return;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectRepository.update(props.projectId, {
        [target]: currentValue,
      });
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
      props.lastSavedFormValue.current = {
        ...props.lastSavedFormValue.current,
        [target]: currentValue,
      };
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
      props.setReportContentState({
        ...props.reportContentState,
        [target]: props.lastSavedFormValue.current[target],
      });
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <ReportContentPrintModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        selectedClient={props.selectedClient}
        state={props.allState}
      />

      <Box sx={{ my: "1.7rem" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: "16px" }}>
          <CustomFormLabel labelName="受注物件報告書" />
          <Button sx={{ ml: "0.5rem" }} variant="contained" onClick={() => setIsOpen(true)}>
            報告内容プレビュー
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.customPrimary[50],
            border: `1px solid ${theme.palette.grayScale[300]}`,
            borderRadius: "5px",
            py: "1rem",
            px: "1rem",
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "11rem", mr: "2rem" }}>
              <CustomFormLabel labelName="依頼元" />
              <TextField
                name="requester"
                value={props.reportContentState.requester}
                onChange={(e) => {
                  props.setReportContentState({
                    ...props.reportContentState,
                    requester: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  handleUpdate(value, "requester");
                }}
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "11rem" }}>
              <CustomFormLabel labelName="依頼担当者名" />
              <TextField
                name="requestedByPersonName"
                value={props.reportContentState.requestedByPersonName}
                onChange={(e) => {
                  props.setReportContentState({
                    ...props.reportContentState,
                    requestedByPersonName: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  handleUpdate(value, "requestedByPersonName");
                }}
                sx={{ width: "100%" }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: "1rem" }}>
            <CustomFormLabel labelName="報告内容" toolTipTitle="受注物件報告書に表示されます" />
            <TextField
              name="reportContent"
              value={props.reportContentState.reportContent}
              onChange={(e) => {
                props.setReportContentState({
                  ...props.reportContentState,
                  reportContent: e.target.value,
                });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                handleUpdate(value, "reportContent");
              }}
              multiline
              maxRows={4}
              sx={{ width: "100%" }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
