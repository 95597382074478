import { theme } from "extensions/theme";

export const styles = {
  typography: {
    fontSize: "14px",
    fontWeight: "500",
    width: "60px",
    color: theme.palette.grayScale[700],
  },
};
