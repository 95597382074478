import { useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { theme } from "extensions/theme";

interface Props {
  projectBillings: ProjectBilling[];
  salesAmount: number;
}

export const BillingSummaryCard = (props: Props) => {
  const sumAmount = useMemo(
    () => props.projectBillings.reduce((total, item) => total + item.amount, 0),
    [props.projectBillings],
  );
  const sumTaxIncludedAmount = useMemo(
    () => props.projectBillings.reduce((total, item) => total + item.amount + item.tax, 0),
    [props.projectBillings],
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          backgroundColor: theme.palette.customPrimary[50],
          border: `1px solid ${theme.palette.grayScale[300]}`,
          borderRadius: "8px",
          p: "12px",
          width: "100%",
          mb: "16px",
        }}
      >
        <div style={{ width: "50%" }}>
          <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
            請求金額合計
          </Typography>
          <Typography sx={{ fontWeight: "500", ml: "4px", my: "11px" }}>
            ¥ {sumAmount.toLocaleString()}
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            税込:{sumTaxIncludedAmount.toLocaleString()}
          </Typography>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", gap: "8px" }}>
            <Typography
              sx={{ width: "48px", fontSize: "12px", color: theme.palette.grayScale[700] }}
            >
              売上
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              ¥ {props.salesAmount.toLocaleString()}
            </Typography>
          </div>
          <Divider sx={{ my: "12px" }} />
          <div style={{ display: "flex", gap: "8px" }}>
            <Typography
              sx={{ width: "48px", fontSize: "12px", color: theme.palette.grayScale[700] }}
            >
              請求残
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              ¥ {(props.salesAmount - sumAmount).toLocaleString()}
            </Typography>
          </div>
        </div>
      </Box>
    </>
  );
};
