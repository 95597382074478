import { useState } from "react";
import {
  AutocompleteChangeReason,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { Flash } from "components/atoms/flash";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { expenseRepository } from "features/cost/api/expense.repository";
import { expenseItemRepository } from "features/cost/api/expense_item.repository";
import { ExpenseCreateRequest, initialExpenseFormRequest } from "features/cost/types/expense.dto";
import { ExpenseItem } from "features/cost/types/expense_item.dto";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleStateError } from "utils/errorHandler";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: ProjectId;
}
export const ExpenseCreateModal = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isNewCreate, setIsNewCreate] = useState<boolean>(false);
  const [formState, setFormState] = useState<ExpenseCreateRequest>({
    amount: 0,
    note: "",
  });

  const { data: expenseItems } = useSWR(
    props.isOpen ? "/api/v1/expense_items" : null,
    expenseItemRepository.index,
  );

  const handleCheckNewCreate = () => {
    setIsNewCreate(!isNewCreate);
  };

  const handleSelectExpenseItem = async (
    _: React.SyntheticEvent,
    value: ExpenseItem,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason !== "selectOption") return;
    setFormState({
      ...formState,
      expenseItemId: value.id,
      expenseItem: {
        ...formState.expenseItem,
      },
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case "name":
        setFormState({
          ...formState,
          expenseItem: {
            ...formState.expenseItem,
            [e.target.name]: e.target.value,
          },
        });
        break;
      default:
        setFormState({
          ...formState,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const handleSubmit = async () => {
    const convertedFormState: ExpenseCreateRequest = isNewCreate
      ? { ...formState, expenseItemId: undefined, expenseItem: formState.expenseItem }
      : { ...formState, expenseItemId: formState.expenseItemId, expenseItem: undefined };

    try {
      await expenseRepository.create(props.projectId, convertedFormState);
      handleClose();
      mutate(API_PATHS.getProjectExpenseCosts(props.projectId));
    } catch (error) {
      handleStateError(error, setErrorMessage, "経費の追加に失敗しました");
    }
  };

  const handleClose = () => {
    setErrorMessage("");
    setFormState(initialExpenseFormRequest);
    setIsNewCreate(false);
    props.onClose();
  };

  const isValid = (): boolean => {
    if (!isNewCreate && !formState.expenseItemId) return false;
    if (isNewCreate && !formState.expenseItem?.name) return false;
    if (!formState.amount) return false;
    return true;
  };

  return (
    <CustomModal
      open={props.isOpen}
      onClose={handleClose}
      title="経費項目の追加"
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "10rem" }}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid()}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "10rem", ml: "1rem" }}
          >
            追加
          </Button>
        </>
      }
    >
      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}

      <CustomAutocomplete
        variant="standard"
        data={expenseItems || []}
        value={formState.expenseItemId}
        onChange={handleSelectExpenseItem}
        entityName="経費項目"
        entityLabel="経費項目を検索または選択"
        disabled={isNewCreate}
      />

      <FormControlLabel
        sx={{ ml: 0, my: "12px" }}
        labelPlacement="end"
        label={
          <Typography sx={{ fontWeight: "500", fontSize: "14px", userSelect: "none" }}>
            経費項目を新規作成して追加する
          </Typography>
        }
        control={
          <Checkbox
            name="newCreateExpenseItem"
            sx={{ p: 0, pr: 1 }}
            onChange={handleCheckNewCreate}
            checked={isNewCreate}
          />
        }
      />

      <Collapse in={isNewCreate} timeout="auto">
        <div style={{ marginBottom: "8px" }}>
          <CustomFormLabel
            labelName="経費項目名"
            labelColor={theme.palette.grayScale[900]}
            labelWeight="bold"
            required
            requiredSize="14px"
          />
          <TextField
            name="name"
            value={formState.expenseItem?.name}
            onChange={handleChange}
            sx={{ width: "100%" }}
            placeholder="経費項目名を入力"
          />
        </div>
      </Collapse>

      <div style={{ marginBottom: "8px" }}>
        <CustomFormLabel
          labelName="金額"
          labelColor={theme.palette.grayScale[900]}
          labelWeight="bold"
          required
          requiredSize="14px"
        />
        <AmountTextField
          name="amount"
          width="100%"
          height="inherit"
          value={formState.amount}
          onChange={handleChange}
          step={100}
          isBgWhite
        />
      </div>
      <div>
        <CustomFormLabel
          labelName="メモ"
          labelColor={theme.palette.grayScale[900]}
          labelWeight="bold"
        />
        <TextField
          name="note"
          value={formState.note}
          onChange={handleChange}
          sx={{ width: "100%" }}
          placeholder="メモを入力"
          multiline
          rows={3}
        />
      </div>
    </CustomModal>
  );
};
