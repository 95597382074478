import { IconButton, TextField, Typography } from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";
import { DeleteIcon } from "components/icon/delete-icon";
import { theme } from "extensions/theme";
import { LaborCost, LaborCostId } from "features/cost/types/labor-cost.dto";
import { styles } from "./styles";

interface Props {
  laborCost: LaborCost;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: LaborCostId) => void;
  onBlur: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    id: LaborCostId,
  ) => void;
  onDelete: (id: LaborCostId) => void;
}
export const LaborCostCard = (props: Props) => {
  const classes = styles();

  return (
    <div className={classes.card}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          {props.laborCost.employee.name}
        </Typography>
        <IconButton onClick={() => props.onDelete(props.laborCost.id)}>
          <DeleteIcon size={14} color={theme.palette.grayScale[700]} />
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "end", gap: "8px" }}>
          <div>
            <Typography className={classes.label} fontSize="12px">
              単価
            </Typography>
            <AmountTextField
              name="unitPrice"
              width="140px"
              value={props.laborCost.unitPrice}
              onChange={(e) => props.onChange(e, props.laborCost.id)}
              blurFunc={(e) => props.onBlur(e, props.laborCost.id)}
              step={100}
              isBgWhite
            />
          </div>
          <div>
            <Typography className={classes.label} fontSize="12px">
              単位
            </Typography>
            <TextField
              name="quantity"
              value={props.laborCost.quantity}
              type="number"
              onChange={(e) => props.onChange(e, props.laborCost.id)}
              onBlur={(e) => props.onBlur(e, props.laborCost.id)}
              slotProps={{
                htmlInput: {
                  step: 0.5,
                },
              }}
              sx={{
                width: "72px",
                bgcolor: theme.palette.grayScale[0],
                "& .MuiOutlinedInput-root": {
                  height: "38px",
                },
              }}
            />
          </div>
          <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>人工</Typography>
        </div>
        <Typography sx={{ fontSize: "14px", mr: "10px" }}>
          ¥{(props.laborCost.unitPrice * props.laborCost.quantity).toLocaleString()}
        </Typography>
      </div>
    </div>
  );
};
