import React, { useRef, useCallback, useState, useEffect } from "react";
import { AccountCircle, Menu } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Box,
  Toolbar,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Badge,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { NoticeLogBlock } from "components/molecules/notice-log-block";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import { NoticeLog } from "data-access/repositories/notice/log/logs.dto";
import { noticeLogRepository } from "data-access/repositories/notice/log/logs.repository";
import { theme } from "extensions/theme";
import Logo from "images/logo.svg";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import { logout } from "utils/logout";
import { drawerWidth } from "../sidebar";

interface HeaderProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Header = (props: HeaderProps) => {
  const { open, setOpen } = props;
  const anchorRefForUser = useRef<HTMLButtonElement>(null);
  const anchorRefForNotice = useRef<HTMLButtonElement>(null);

  const [noticePopperOpen, setNoticePopperOpen] = useState<boolean>(false);
  const [userPopperOpen, setUserPopperOpen] = useState<boolean>(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handlePopperOpen = (target: "notice" | "user") => {
    if (target === "notice") {
      setNoticePopperOpen((prev) => !prev);
    }
    if (target === "user") {
      setUserPopperOpen((prev) => !prev);
    }
  };

  const handleClick = () => {
    logout(window.location.pathname);
  };

  const handleClose = useCallback(
    (event: any, target: "notice" | "user") => {
      if (
        (target === "user" &&
          anchorRefForUser.current &&
          anchorRefForUser.current.contains(event.target)) ||
        (target === "notice" &&
          anchorRefForNotice.current &&
          anchorRefForNotice.current.contains(event.target))
      ) {
        return;
      }
      if (target === "user") {
        setUserPopperOpen(false);
      } else if (target === "notice") {
        setNoticePopperOpen(false);
        mutate();
      }
    },
    [setUserPopperOpen, setNoticePopperOpen],
  );
  const { data: noticeLogsCount, mutate } = useSWR(
    "/api/v1/notice/logs/count_info",
    noticeLogRepository.countInfo,
  );

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <div style={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <Menu />
            </IconButton>
            <img src={Logo} alt="logo" style={{ width: 150 }} />
            <div style={{ display: "flex" }}>
              <IconButton
                color="inherit"
                ref={anchorRefForNotice}
                aria-controls={noticePopperOpen ? "menu-list-grow-notice" : undefined}
                aria-haspopup="true"
                onClick={() => handlePopperOpen("notice")}
              >
                <Badge color="error" badgeContent={noticeLogsCount?.unreadCount} max={99}>
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                color="inherit"
                ref={anchorRefForUser}
                aria-controls={userPopperOpen ? "menu-list-grow-user" : undefined}
                aria-haspopup="true"
                onClick={() => handlePopperOpen("user")}
              >
                <AccountCircle />
              </IconButton>
            </div>
            <UserMenu
              isOpen={userPopperOpen}
              anchorRef={anchorRefForUser}
              onClick={handleClick}
              onClose={(event) => handleClose(event, "user")}
            />
            <NoticeMenu
              isOpen={noticePopperOpen}
              anchorRef={anchorRefForNotice}
              onClose={(event) => handleClose(event, "notice")}
            />
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
};

interface UserMenuProps {
  isOpen: boolean;
  anchorRef: React.RefObject<HTMLButtonElement>;
  onClick: () => void;
  onClose: (event: any) => void;
}
const UserMenu = (props: UserMenuProps) => {
  const { isOpen, anchorRef, onClick: handleClick, onClose: handleClose } = props;
  const cookie = cookieRepository.get();

  return (
    <Popper open={isOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={isOpen} id="menu-list-grow-user">
                <MenuItem onClick={handleClose} disabled>
                  {cookie.company_name}
                </MenuItem>
                <MenuItem onClick={handleClose} disabled>
                  {cookie.email}でログイン中
                </MenuItem>
                <MenuItem sx={{ display: "flex", justifyContent: "space-between" }} disabled>
                  <Typography component="span">{cookie.name}</Typography>
                  <Typography component="span" fontSize={12}>
                    version: {import.meta.env.VITE_APP_VERSION}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleClick}>ログアウト</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

interface NoticeMenuProps {
  isOpen: boolean;
  anchorRef: React.RefObject<HTMLButtonElement>;
  onClose: (event: any) => void;
}
const NoticeMenu = (props: NoticeMenuProps) => {
  const LIMIT = 30;

  const getKey = (pageIndex: number, previousPageData: NoticeLog[] | null) => {
    if (!props.isOpen) return null;
    if (previousPageData && !previousPageData.length) return null;
    const limit = (pageIndex + 1) * LIMIT;
    return [`/api/v1/notice/logs?limit=${limit}`, limit];
  };

  const fetcher = (key: [string, number]) => {
    const limit = key[1];
    return noticeLogRepository.index(limit);
  };

  const {
    data: rawData,
    size,
    setSize,
    isLoading,
    mutate,
  } = useSWRInfinite<NoticeLog[]>(getKey, fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });

  const { data: noticeLogsCount = { allCount: 0 } } = useSWR(
    "/api/v1/notice/logs/count_info",
    noticeLogRepository.countInfo,
  );

  useEffect(() => {
    if (props.isOpen) {
      mutate();
    }
  }, [props.isOpen]);

  const noticeLogs: NoticeLog[][] = rawData || [];
  const isLoadingMore =
    isLoading || (size > 0 && rawData && typeof rawData[size - 1] === "undefined");
  const isReachingEnd = rawData
    ? rawData[rawData.length - 1].length >= noticeLogsCount?.allCount &&
      noticeLogsCount?.allCount !== 0
    : false;

  return (
    <Popper
      open={props.isOpen}
      anchorEl={props.anchorRef.current}
      role={undefined}
      disablePortal
      sx={{ width: "260px" }}
      placement="bottom-end"
    >
      <Paper
        sx={{ maxHeight: "300px", overflowY: "auto", width: "260px" }}
        onScroll={(e: React.UIEvent<HTMLDivElement>) => {
          const bottom =
            e.currentTarget.scrollHeight - Math.ceil(e.currentTarget.scrollTop) ===
            e.currentTarget.clientHeight;
          if (bottom && !isLoadingMore && !isReachingEnd) {
            setSize(size + 1);
          }
        }}
      >
        <ClickAwayListener onClickAway={props.onClose}>
          <MenuList sx={{ p: 0 }}>
            {noticeLogs.length > 0 &&
              noticeLogs[noticeLogs.length - 1].map((notice) => (
                <React.Fragment key={notice.id}>
                  <Box
                    sx={{
                      width: "100%",
                      bgcolor: notice.isRead
                        ? "inherit"
                        : notice.noticeType === "overtime_records"
                          ? theme.palette.red[200]
                          : theme.palette.customPrimary[100],
                    }}
                  >
                    <NoticeLogBlock data={notice} />
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
            {isLoadingMore && (
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            )}
            {isReachingEnd && (
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <Typography variant="body2" color="textSecondary">
                  これ以上の通知はありません
                </Typography>
              </Box>
            )}
            {noticeLogs.length > 0 && !noticeLogs[0].length && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  padding: "20px",
                }}
              >
                <NotificationsIcon sx={{ fontSize: "48px" }} color="disabled" />
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                  現在、通知はありません
                </Typography>
              </div>
            )}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};
