import { useEffect, useMemo, useState } from "react";
import { Box, Collapse, Divider, SelectChangeEvent, Typography } from "@mui/material";
import { OutsourcingIcon } from "components/icon/outsourcing-icon";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { outsourcingCostRepository } from "features/cost/api/outsourcing_cost.repository";
import {
  OutsourcingCost,
  OutsourcingCostId,
  OutsourcingCostIndexResponse,
} from "features/cost/types/outsourcing_cost.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { OutsourcingCostCard } from "./card";
import { OutsourcingCostFormModal } from "./form-modal";
import { styles } from "./styles";
import { AddButton } from "../add_button";

interface Props {
  projectId: ProjectId;
  data: OutsourcingCostIndexResponse;
}

export const OutsourcingCostList = (props: Props) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [formState, setFormState] = useState<OutsourcingCostIndexResponse>([]);

  const totalOutsourcingCost: number = useMemo(() => {
    return formState.reduce((acc, cur) => acc + cur.unitPrice * cur.quantity, 0);
  }, [formState]);

  useEffect(() => {
    if (!props.data) return;
    setFormState(props.data);
  }, [props.data]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    outsourcingCostId: OutsourcingCostId,
  ) => {
    const results: OutsourcingCost[] = formState.map((v) => {
      if (v.id === outsourcingCostId) {
        return { ...v, [e.target.name]: Number(e.target.value) };
      }
      return v;
    });
    setFormState(results);
  };

  const handleBlur = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    outsourcingCostId: OutsourcingCostId,
  ) => {
    try {
      await outsourcingCostRepository.update(props.projectId, outsourcingCostId, {
        [e.target.name]: Number(e.target.value),
      });
      mutate(API_PATHS.getProjectOutsourcingCosts(props.projectId));
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      setFormState(props.data);
    }
  };

  const handleSelect = async (e: SelectChangeEvent<string>, id: OutsourcingCostId) => {
    try {
      await outsourcingCostRepository.update(props.projectId, id, { unitType: e.target.value });
      mutate(API_PATHS.getProjectOutsourcingCosts(props.projectId));
      dispatch(mainOperations.updateSuccessMessage("更新しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      setFormState(props.data);
    }
  };

  const handleDelete = async (id: OutsourcingCostId) => {
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await outsourcingCostRepository.destroy(props.projectId, id);
      mutate(API_PATHS.getProjectOutsourcingCosts(props.projectId));
      dispatch(mainOperations.updateSuccessMessage("削除しました"));
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <OutsourcingCostFormModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        projectId={props.projectId}
      />

      <Box
        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div style={{ display: "flex", gap: "8px" }}>
          <div className={classes.badge}>
            <OutsourcingIcon />
            <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>外注費</Typography>
          </div>
          <Typography fontWeight="bold" color="primary">
            ¥{totalOutsourcingCost.toLocaleString()}
          </Typography>
        </div>
        <AccordionToggle
          title=""
          isExpanded={isExpanded}
          setIsExpanded={() => setIsExpanded(!isExpanded)}
        />
      </Box>
      <Divider sx={{ my: "12px" }} />
      <Collapse
        in={isExpanded}
        timeout="auto"
        sx={{
          ml: "80px",
          "& .MuiCollapse-wrapperInner": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          },
        }}
      >
        {formState?.map((outsourcingCost, index) => (
          <OutsourcingCostCard
            key={index}
            outsourcingCost={outsourcingCost}
            onChange={(e, id) => handleChange(e, id)}
            onBlur={(e, id) => handleBlur(e, id)}
            onSelect={(e, id) => handleSelect(e, id)}
            onDelete={() => handleDelete(outsourcingCost.id)}
          />
        ))}
        <AddButton
          onOpen={() => setIsCreateModalOpen(true)}
          entityName="協力会社"
          width="400px"
          height="100px"
        />
      </Collapse>
    </>
  );
};
