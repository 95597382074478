import { ElementRef, useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { AmountTextField } from "components/atoms/amount-text-field";
import { Flash } from "components/atoms/flash";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { theme } from "extensions/theme";
import { expenseRepository } from "features/cost/api/expense.repository";
import {
  ExpenseId,
  ExpenseUpdateRequest,
  initialExpenseFormRequest,
} from "features/cost/types/expense.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleStateError } from "utils/errorHandler";
import type { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: ProjectId;
  expenseId: ExpenseId;
  name: string;
  state: ExpenseUpdateRequest;
  confirmRef: React.RefObject<ElementRef<typeof AsyncConfirmDialog>>;
}
export const ExpenseEditModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formState, setFormState] = useState<ExpenseUpdateRequest>({
    amount: 0,
    note: "",
  });

  useEffect(() => {
    if (!props.isOpen) return;
    setFormState({
      amount: props.state.amount,
      note: props.state.note,
    });
  }, [props.isOpen]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      await expenseRepository.update(props.projectId, props.expenseId, formState);
      handleClose();
      mutate(API_PATHS.getProjectExpenseCosts(props.projectId));
    } catch (error) {
      handleStateError(error, setErrorMessage, "仕入先の更新に失敗しました");
    }
  };

  const handleDelete = async () => {
    if (!props.confirmRef.current) return;
    const res = await props.confirmRef.current.confirm();
    if (res) {
      try {
        await expenseRepository.destroy(props.projectId, props.expenseId);
        mutate(API_PATHS.getProjectExpenseCosts(props.projectId));
        handleClose();
        dispatch(mainOperations.updateSuccessMessage("仕入先を削除しました"));
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    }
  };

  const handleClose = () => {
    setErrorMessage("");
    setFormState(initialExpenseFormRequest);
    props.onClose();
  };

  const isValid = (): boolean => {
    if (!formState.amount) return false;
    return true;
  };

  return (
    <CustomModal
      open={props.isOpen}
      onClose={handleClose}
      title="経費項目の編集"
      maxWidth="sm"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} sx={{ width: "160px" }}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            color="error"
            sx={{ width: "160px", ml: "16px" }}
          >
            削除
          </Button>
          <Button
            disabled={!isValid()}
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "160px", ml: "16px" }}
          >
            保存
          </Button>
        </>
      }
    >
      {errorMessage && (
        <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
      )}

      <div style={{ marginBottom: "24px" }}>
        <CustomFormLabel
          labelName="仕入先名"
          labelColor={theme.palette.grayScale[900]}
          labelWeight="bold"
        />
        <Typography
          sx={{
            fontSize: "14px",
            width: "100%",
            bgcolor: theme.palette.primary.light,
            borderRadius: "4px",
            color: theme.palette.grayScale[700],
            p: "8px",
          }}
        >
          {props.name}
        </Typography>
      </div>

      <div style={{ marginBottom: "24px" }}>
        <CustomFormLabel
          labelName="金額"
          labelColor={theme.palette.grayScale[900]}
          labelWeight="bold"
          required
          requiredSize="14px"
        />
        <AmountTextField
          name="amount"
          width="100%"
          height="inherit"
          value={formState.amount}
          onChange={handleChange}
          step={100}
          isBgWhite
        />
      </div>
      <div>
        <CustomFormLabel
          labelName="メモ"
          labelColor={theme.palette.grayScale[900]}
          labelWeight="bold"
        />
        <TextField
          name="note"
          value={formState.note}
          onChange={handleChange}
          sx={{ width: "100%" }}
          placeholder="メモを入力"
          multiline
          rows={3}
        />
      </div>
    </CustomModal>
  );
};
