import React from "react";
import { Box } from "@mui/material";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import {
  ProjectFormDatesState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { ACTIVITY_PER_PAGE } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";

interface FormValues {
  inquiredDate: Date | null;
  orderedDate: Date | null;
  expectedCompleteDate: Date | null;
  completedDate: Date | null;
}

interface Props {
  isCreateNew: boolean;
  projectId?: ProjectId;
  datesState: ProjectFormDatesState;
  setDatesState: React.Dispatch<React.SetStateAction<ProjectFormDatesState>>;
  lastSavedFormValue: React.MutableRefObject<ProjectFormUpdateRequest>;
  onCreateProject: (targetName: string, value: any) => void;
  currentPage: number;
}
export const ProjectSidebarDateContent = React.memo((props: Props) => {
  const dispatch = useAppDispatch();

  const handleChange = (
    date: Date,
    e: React.SyntheticEvent<any> | undefined,
    target: keyof FormValues,
  ) => {
    if (!props.projectId) return;
    const dateString = date?.toString() || "";
    if (e && e.type === "click") {
      handleUpdate(dateString, target);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target as { name: keyof FormValues };
    const fieldValue = props.datesState[name].toString();
    if (props.lastSavedFormValue.current[name] === fieldValue) return;

    handleUpdate(fieldValue, name);
  };

  const handleUpdate = async (date: string, target: keyof FormValues) => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectRepository.update(props.projectId, {
        [target]: date,
      });
      props.lastSavedFormValue.current = {
        ...props.lastSavedFormValue.current,
        [target]: date,
      };
      dispatch(mainOperations.updateSuccessMessage("日付を更新しました"));
      mutate([API_PATHS.getProjects(), props.currentPage]);
      mutate(API_PATHS.getProjectActivities(props.projectId, ACTIVITY_PER_PAGE));
    } catch (error) {
      handleReduxError(error, dispatch, "日付の更新に失敗しました");
      props.setDatesState((prev) => ({
        ...prev,
        [target]: props.lastSavedFormValue.current[target]
          ? new Date(props.lastSavedFormValue.current[target] as string)
          : null,
      }));
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      <div>
        <CustomFormLabel labelName="受付日" small />
        <CustomDatePicker
          id="inquiredDate"
          name="inquiredDate"
          date={props.datesState.inquiredDate ? new Date(props.datesState.inquiredDate) : null}
          onChange={(date, e) => {
            props.setDatesState((prev) => ({
              ...prev,
              inquiredDate: date ? date.toString() : "",
            }));
            if (props.isCreateNew) {
              props.onCreateProject("inquiredDate", date);
            } else {
              handleChange(date, e, "inquiredDate");
            }
          }}
          onBlur={handleBlur}
          isClearable
          popperPlacement={"bottom-start"}
        />
      </div>
      <div>
        <CustomFormLabel labelName="受注日" small />
        <CustomDatePicker
          id="orderedDate"
          name="orderedDate"
          date={props.datesState.orderedDate ? new Date(props.datesState.orderedDate) : null}
          onChange={(date, e) => {
            props.setDatesState((prev) => ({
              ...prev,
              orderedDate: date ? date.toString() : "",
            }));
            if (props.isCreateNew) {
              props.onCreateProject("orderedDate", date);
            } else {
              handleChange(date, e, "orderedDate");
            }
          }}
          onBlur={handleBlur}
          isClearable
        />
      </div>
      <div>
        <CustomFormLabel labelName="完了予定日" small />
        <CustomDatePicker
          id="expectedCompleteDate"
          name="expectedCompleteDate"
          date={
            props.datesState.expectedCompleteDate
              ? new Date(props.datesState.expectedCompleteDate)
              : null
          }
          onChange={(date, e) => {
            props.setDatesState((prev) => ({
              ...prev,
              expectedCompleteDate: date ? date.toString() : "",
            }));
            if (props.isCreateNew) {
              props.onCreateProject("expectedCompleteDate", date);
            } else {
              handleChange(date, e, "expectedCompleteDate");
            }
          }}
          onBlur={handleBlur}
          isClearable
        />
      </div>
      <div>
        <CustomFormLabel labelName="完了日" small />
        <CustomDatePicker
          id="completedDate"
          name="completedDate"
          date={props.datesState.completedDate ? new Date(props.datesState.completedDate) : null}
          onChange={(date, e) => {
            props.setDatesState((prev) => ({
              ...prev,
              completedDate: date ? date.toString() : "",
            }));
            if (props.isCreateNew) {
              props.onCreateProject("completedDate", date);
            } else {
              handleChange(date, e, "completedDate");
            }
          }}
          onBlur={handleBlur}
          isClearable
        />
      </div>
    </Box>
  );
});
