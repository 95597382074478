import { theme } from "extensions/theme";

export const styles = {
  thumbnailArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: "8px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    width: "43px",
    height: "43px",
  },
};
