import { theme } from "extensions/theme";

export const styles = {
  typography: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "bold",
    height: "30px",
    color: theme.palette.text.secondary,
  },
};
