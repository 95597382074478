import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TIFFViewer } from "components/atoms/tiff-preview";
import { isContainExtensions } from "utils/isContainExtensions";
import { styles } from "./styles";

const CustomTIFFViewer = styled(TIFFViewer)({
  canvas: {
    "max-width": "100%",
    "max-height": "inherit",
  },
  div: {
    margin: "0 auto",
  },
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  image: {
    name: string;
    url: string;
  };
}
export const ImagePreviewModal = (props: Props) => {
  const classes = styles();

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} className={classes.dialog}>
      <DialogTitle className={classes.title}>
        <Typography>{props.image.name}</Typography>
        <IconButton className={classes.closeButton} onClick={props.onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <Box sx={{ textAlign: "center" }}>
          {isContainExtensions(props.image.name, [".tif", ".tiff"]) ? (
            <CustomTIFFViewer tiff={props.image.url} />
          ) : (
            <img
              alt={props.image.name}
              src={props.image.url}
              style={{ maxHeight: "600px", minWidth: "450px" }}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
