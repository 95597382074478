import { theme } from "extensions/theme";

export const styles = {
  tableHeader: {
    display: "flex",
    "& .MuiInputBase-root": {
      height: "1.928rem",
      fontSize: "0.857rem",
      width: "13rem",
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.main} `,
      opacity: ".5",
    },
  },
};
