import { useState } from "react";
import { postalCodeRepository } from "data-access/repositories/postal_code/postal_code.repository";

interface UseGetPostalCodeReturn {
  postalCodeErrorMessage: string;
  searchPostalCodeByAddress: (address: string) => Promise<string | undefined>;
}

export const useGetPostalCode = (): UseGetPostalCodeReturn => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const searchPostalCodeByAddress = async (address: string) => {
    try {
      const res = await postalCodeRepository.index(address);
      const postalCode = res.data[0].postal_code;
      setErrorMessage("");
      return postalCode;
    } catch {
      setErrorMessage("郵便番号が見つかりませんでした");
      return "";
    }
  };

  return {
    postalCodeErrorMessage: errorMessage,
    searchPostalCodeByAddress,
  };
};
