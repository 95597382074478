import { useEffect, useState } from "react";
import {
  initialProjectFormBillingState,
  initialProjectFormDatesState,
  initialProjectFormHeaderState,
  initialProjectFormIdentificationState,
  initialProjectFormNotesState,
  initialProjectFormReportContentsState,
  initialProjectFormSalesState,
  initialProjectFormSiteState,
  Project,
  ProjectFormBillingState,
  ProjectFormDatesState,
  ProjectFormHeaderState,
  ProjectFormIdentificationState,
  ProjectFormNotesState,
  ProjectFormReportContentsState,
  ProjectFormSalesState,
  ProjectFormSiteState,
  ProjectFormUpdateRequest,
} from "data-access/repositories/project/project.dto";

export const useProjectForm = (project: Project | undefined) => {
  const [headerState, setHeaderState] = useState<ProjectFormHeaderState>(
    initialProjectFormHeaderState,
  );
  const [identificationState, setIdentificationState] = useState<ProjectFormIdentificationState>(
    initialProjectFormIdentificationState,
  );
  const [datesState, setDatesState] = useState<ProjectFormDatesState>(initialProjectFormDatesState);
  const [salesState, setSalesState] = useState<ProjectFormSalesState>(initialProjectFormSalesState);
  const [notesState, setNotesState] = useState<ProjectFormNotesState>(initialProjectFormNotesState);
  const [siteState, setSiteState] = useState<ProjectFormSiteState>(initialProjectFormSiteState);
  const [billingsState, setBillingsState] = useState<ProjectFormBillingState>(
    initialProjectFormBillingState,
  );
  const [reportContentsState, setReportContentsState] = useState<ProjectFormReportContentsState>(
    initialProjectFormReportContentsState,
  );

  // projectが更新されたら状態を更新
  useEffect(() => {
    if (project) {
      setHeaderState({
        name: project.name,
        projectStatusTypeId: project.project_status_type.id,
        clientId: project.client?.id || null,
        buildingId: project.building?.id || null,
      });
      setIdentificationState({
        code: project.code,
        inquiryNumber: project.inquiry_number,
        managerId: project.manager?.id || null,
        inquiredById: project.inquired_by?.id || null,
        projectTypeId: project.project_type.id,
      });
      setDatesState({
        inquiredDate: project.inquired_date || "",
        orderedDate: project.ordered_date || "",
        expectedCompleteDate: project.expected_complete_date || "",
        completedDate: project.completed_date || "",
      });
      setSalesState({
        salesAmount: project.sales_amount || 0,
        tax: project.sales_tax || 0,
        totalCostAmount: project.total_cost_amount || 0,
      });
      setNotesState({
        note: project.note,
        billingPrecaution: project.billing_precaution,
      });
      setSiteState({
        personName: project.person_name,
        postalCode: project.postal_code,
        address: project.address,
        addressSecond: project.address_second,
        phoneNumber: project.phone_number,
        phoneNumberSecond: project.phone_number_second,
        faxNumber: project.fax_number,
        email: project.email,
      });
      setBillingsState({
        isManageBillingOnAnotherSystem: project.is_manage_billing_on_another_system,
        isNoBill: project.is_no_bill,
        isSupportedByMaker: project.is_supported_by_maker,
      });
      setReportContentsState({
        requester: project.requester,
        requestedByPersonName: project.requested_by_person_name,
        reportContent: project.report_content,
      });
    }
  }, [project]);

  // フォーム全体の値を取得する関数
  const getAllState = (): ProjectFormUpdateRequest => {
    return {
      ...headerState,
      ...identificationState,
      ...datesState,
      ...salesState,
      ...notesState,
      ...siteState,
      ...billingsState,
      ...reportContentsState,
    };
  };

  return {
    headerState,
    setHeaderState,
    identificationState,
    setIdentificationState,
    datesState,
    setDatesState,
    salesState,
    setSalesState,
    notesState,
    setNotesState,
    siteState,
    setSiteState,
    billingsState,
    setBillingsState,
    reportContentsState,
    setReportContentsState,
    getAllState,
  };
};
