import { ElementRef, useEffect, useMemo, useState } from "react";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { Box, Collapse, Divider, Typography } from "@mui/material";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { ProjectId } from "data-access/repositories/project/project.dto";
import {
  ExpenseId,
  ExpenseIndexResponse,
  ExpenseUpdateRequest,
} from "features/cost/types/expense.dto";
import { ExpenseCard } from "./card";
import { ExpenseCreateModal } from "./create-modal";
import { ExpenseEditModal } from "./edit-modal";
import { styles } from "./styles";
import { AddButton } from "../add_button";
import type { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";

interface Props {
  projectId: ProjectId;
  data: ExpenseIndexResponse;
  confirmRef: React.RefObject<ElementRef<typeof AsyncConfirmDialog>>;
}
export const ExpenseList = (props: Props) => {
  const classes = styles();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<{
    isOpen: boolean;
    expenseId: ExpenseId;
    name: string;
    body: { amount: number; note: string };
  }>({ isOpen: false, expenseId: 0 as ExpenseId, name: "", body: { amount: 0, note: "" } });
  const [state, setState] = useState<ExpenseIndexResponse>([]);

  const totalExpense: number = useMemo(() => {
    return state.reduce((acc, cur) => acc + cur.amount, 0);
  }, [state]);

  useEffect(() => {
    if (!props.data) return;
    setState(props.data);
  }, [props.data]);

  const handleEditModalOpen = (id: ExpenseId, name: string, body: ExpenseUpdateRequest) => {
    setEditModal({ isOpen: true, expenseId: id, name, body });
  };

  return (
    <>
      <ExpenseCreateModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        projectId={props.projectId}
      />
      <ExpenseEditModal
        isOpen={editModal.isOpen}
        onClose={() =>
          setEditModal({
            isOpen: false,
            expenseId: 0 as ExpenseId,
            name: "",
            body: { amount: 0, note: "" },
          })
        }
        projectId={props.projectId}
        expenseId={editModal.expenseId}
        name={editModal.name}
        state={editModal.body}
        confirmRef={props.confirmRef}
      />

      <Box
        sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div style={{ display: "flex", gap: "8px" }}>
          <Box className={classes.badge}>
            <PaidOutlinedIcon fontSize="small" />
            <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>経費</Typography>
          </Box>
          <Typography fontWeight="bold" color="primary">
            ¥{totalExpense.toLocaleString()}
          </Typography>
        </div>
        <AccordionToggle
          title=""
          isExpanded={isExpanded}
          setIsExpanded={() => setIsExpanded(!isExpanded)}
        />
      </Box>
      <Divider sx={{ my: "12px" }} />
      <Collapse
        in={isExpanded}
        timeout="auto"
        sx={{
          ml: "80px",
          "& .MuiCollapse-wrapperInner": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          },
        }}
      >
        {state?.map((expense, index) => (
          <ExpenseCard
            key={index}
            expense={expense}
            setEdit={(id, name, body) => handleEditModalOpen(id, name, body)}
          />
        ))}
        <AddButton
          onOpen={() => setIsCreateModalOpen(true)}
          entityName="経費項目"
          width="248px"
          height="72px"
        />
      </Collapse>
    </>
  );
};
