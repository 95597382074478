import { memo } from "react";
import FaxIcon from "@mui/icons-material/Fax";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { TitleLabel } from "components/label/title-label";
import { Building, BuildingId } from "data-access/repositories/building/building.dto";
import {
  ProjectFormHeaderState,
  ProjectFormSiteState,
  ProjectFormUpdateRequest,
  ProjectId,
} from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { theme } from "extensions/theme";
import { useGetAddress } from "hooks/useGetAddress";
import { useGetPostalCode } from "hooks/useGetPostalCode";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

type FormValues = {
  buildingId: BuildingId | null;
  personName: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  faxNumber: string;
  email: string;
};
interface Props {
  projectId?: ProjectId;
  isCreateNew: boolean;
  headerState: ProjectFormHeaderState;
  setHeaderState: React.Dispatch<React.SetStateAction<ProjectFormHeaderState>>;
  siteState: ProjectFormSiteState;
  setSiteState: React.Dispatch<React.SetStateAction<ProjectFormSiteState>>;
  lastSavedFormValue: React.MutableRefObject<ProjectFormUpdateRequest>;
  onCreateBuilding: () => void;
  onChangeBuilding: (
    e: React.SyntheticEvent<Element, Event>,
    value: { id: BuildingId; name: string; createOption: boolean } | null,
  ) => void;
  rawBuildingsData: Building[];
  selectedBuilding: Building | undefined;
  onCreateProject: (targetName: string, value: any) => void;
}

export const ProjectSidebarSiteContent = memo((props: Props) => {
  const dispatch = useAppDispatch();
  const postalCode = props.siteState.postalCode
    ? props.siteState.postalCode.replace("-", "").replace("ー", "")
    : "";
  const { addressErrorMessage, getAddressByPostalCode } = useGetAddress();
  const { postalCodeErrorMessage, searchPostalCodeByAddress } = useGetPostalCode();

  const buildings = props.rawBuildingsData.map((building) => {
    return {
      id: building.id,
      name: building.name,
      createOption: false,
    };
  });

  const handleClickSearchAddress = async () => {
    if (!props.siteState.postalCode || !props.projectId || props.isCreateNew) return;
    dispatch(mainOperations.updateIsLoading(true));

    const address = await getAddressByPostalCode(props.siteState.postalCode);
    if (address) {
      try {
        await projectRepository.update(props.projectId, { address });
        props.setSiteState((prev) => ({ ...prev, address }));
        props.lastSavedFormValue.current = {
          ...props.lastSavedFormValue.current,
          address,
        };
        dispatch(mainOperations.updateSuccessMessage("住所を更新しました"));
      } catch (error) {
        handleReduxError(error, dispatch, "住所の更新に失敗しました");
      }
    }
    dispatch(mainOperations.updateIsLoading(false));
  };

  const handleClickSearchPostalCode = async () => {
    if (!props.siteState.address || !props.projectId || props.isCreateNew) return;
    dispatch(mainOperations.updateIsLoading(true));

    const postalCode = await searchPostalCodeByAddress(props.siteState.address);
    if (postalCode) {
      try {
        await projectRepository.update(props.projectId, { postalCode });
        props.setSiteState((prev) => ({ ...prev, postalCode }));
        props.lastSavedFormValue.current = {
          ...props.lastSavedFormValue.current,
          postalCode,
        };
        dispatch(mainOperations.updateSuccessMessage("郵便番号を更新しました"));
      } catch (error) {
        handleReduxError(error, dispatch, "郵便番号の更新に失敗しました");
      }
    }
    dispatch(mainOperations.updateIsLoading(false));
  };

  const handleSubmit = async (currentValue: string | number | null, target: keyof FormValues) => {
    if (props.isCreateNew) {
      props.onCreateProject(target, currentValue);
      return;
    }
    if (!props.projectId) return;
    if (currentValue !== props.lastSavedFormValue.current[target]) {
      dispatch(mainOperations.updateIsLoading(true));
      try {
        await projectRepository.update(props.projectId, {
          [target]: currentValue,
        });
        dispatch(mainOperations.updateSuccessMessage("更新しました"));
        props.lastSavedFormValue.current = {
          ...props.lastSavedFormValue.current,
          [target]: currentValue,
        };
      } catch (error) {
        handleReduxError(error, dispatch, "更新に失敗しました");
        props.setSiteState((prev) => ({
          ...prev,
          [target]: props.lastSavedFormValue.current[target],
        }));
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  return (
    <>
      <TitleLabel title="現場情報" sx={{ mb: "8px" }} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CustomAutocomplete
          data={buildings}
          value={props.selectedBuilding || null}
          onChange={async (_, value, reason) => {
            if (reason === "selectOption" && value) {
              props.setHeaderState({ ...props.headerState, buildingId: value.id });
              props.onChangeBuilding(_, value);
            }
            if (reason === "clear") {
              props.setHeaderState({ ...props.headerState, buildingId: null });
              props.onChangeBuilding(_, null);
            }
          }}
          onClick={props.onCreateBuilding}
          isCreate
          entityName="物件"
          entityLabel="物件"
          variant="standard"
          isClearable
          sx={{ width: "250px", mb: "20px" }}
        />
        <TextField
          name="personName"
          variant="standard"
          label="先方担当者"
          value={props.siteState.personName || ""}
          onChange={(e) => {
            props.setSiteState((prev) => ({ ...prev, personName: e.target.value }));
          }}
          onBlur={(e) => {
            const value = e.target.value;
            handleSubmit(value, "personName");
          }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
          gap: "16px",
        }}
      >
        <div>
          <div style={{ display: "flex" }}>
            <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
              郵便番号
            </Typography>
            <Button
              onClick={handleClickSearchAddress}
              disabled={postalCode.length !== 7 || props.isCreateNew}
              sx={{ py: 0, ml: "8px", fontSize: "14px" }}
            >
              住所を検索
            </Button>
          </div>
          <TextField
            name="postalCode"
            error={postalCodeErrorMessage.length > 0}
            helperText={postalCodeErrorMessage}
            value={props.siteState.postalCode || ""}
            onChange={(e) => {
              props.setSiteState((prev) => ({ ...prev, postalCode: e.target.value }));
            }}
            onBlur={(e) => {
              const value = e.target.value;
              handleSubmit(value, "postalCode");
            }}
            slotProps={{
              input: {
                startAdornment: <InputAdornment position="start">〒</InputAdornment>,
              },
            }}
            sx={{ ...styles.textField, width: "150px" }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
              都道府県・市区町村・番地
            </Typography>
            <Button
              sx={{ py: 0 }}
              onClick={handleClickSearchPostalCode}
              disabled={props.siteState.address?.length === 0 || props.isCreateNew}
            >
              郵便番号を検索
            </Button>
          </div>
          <TextField
            name="address"
            value={props.siteState.address}
            error={addressErrorMessage.length > 0}
            helperText={addressErrorMessage}
            onChange={(e) => {
              props.setSiteState((prev) => ({ ...prev, address: e.target.value }));
            }}
            onBlur={(e) => {
              const value = e.target.value;
              handleSubmit(value, "address");
            }}
            sx={{ ...styles.textField, width: "100%" }}
            placeholder="東京都渋谷区神泉町8-1"
          />
        </div>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
          建物名・階・号室
        </Typography>
        <TextField
          name="addressSecond"
          value={props.siteState.addressSecond}
          onChange={(e) => {
            props.setSiteState((prev) => ({ ...prev, addressSecond: e.target.value }));
          }}
          onBlur={(e) => {
            const value = e.target.value;
            handleSubmit(value, "addressSecond");
          }}
          placeholder="フォーラム渋谷神泉7階"
          sx={{ ...styles.textField, width: "100%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <div>
          <Typography sx={{ ...styles.typography }}>
            <PhoneIcon sx={{ width: "18px" }} />
            電話番号1
          </Typography>
          <TextField
            name="phoneNumber"
            value={props.siteState.phoneNumber}
            onChange={(e) => {
              props.setSiteState((prev) => ({ ...prev, phoneNumber: e.target.value }));
            }}
            onBlur={(e) => {
              const value = e.target.value;
              handleSubmit(value, "phoneNumber");
            }}
            placeholder="000-0000-0000"
            sx={{ ...styles.textField }}
          />
        </div>
        <div>
          <Typography sx={{ ...styles.typography }}>
            <PhoneIcon sx={{ width: "18px" }} />
            電話番号2
          </Typography>
          <TextField
            name="phoneNumberSecond"
            value={props.siteState.phoneNumberSecond}
            onChange={(e) => {
              props.setSiteState((prev) => ({ ...prev, phoneNumberSecond: e.target.value }));
            }}
            onBlur={(e) => {
              const value = e.target.value;
              handleSubmit(value, "phoneNumberSecond");
            }}
            placeholder="000-0000-0000"
            sx={{ ...styles.textField }}
          />
        </div>
        <div>
          <Typography sx={{ ...styles.typography }}>
            <FaxIcon sx={{ width: "18px" }} />
            FAX番号
          </Typography>
          <TextField
            name="faxNumber"
            value={props.siteState.faxNumber}
            onChange={(e) => {
              props.setSiteState((prev) => ({ ...prev, faxNumber: e.target.value }));
            }}
            onBlur={(e) => {
              const value = e.target.value;
              handleSubmit(value, "faxNumber");
            }}
            placeholder="000-0000-0000"
            sx={{ ...styles.textField }}
          />
        </div>
        <div>
          <Typography sx={{ ...styles.typography }}>
            <MailOutlineIcon sx={{ width: "18px" }} />
            メールアドレス
          </Typography>
          <TextField
            name="email"
            value={props.siteState.email}
            onChange={(e) => {
              props.setSiteState((prev) => ({ ...prev, email: e.target.value }));
            }}
            onBlur={(e) => {
              const value = e.target.value;
              handleSubmit(value, "email");
            }}
            placeholder="***@gembahub.jp"
            sx={{ ...styles.textField, width: "230px" }}
          />
        </div>
      </div>
    </>
  );
});
