import { ElementRef, memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteIcon } from "components/icon/delete-icon";
import { TodoTagTypeLabel } from "components/label/todo-tag-type-label";
import { AccordionToggle } from "components/molecules/accordion-toggle";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { cookieRepository } from "data-access/cookie/cookie.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import {
  ProjectTodoFormItem,
  ProjectTodoId,
  ProjectTodoRequest,
} from "data-access/repositories/project/todo/todo.dto";
import { projectTodoRepository } from "data-access/repositories/project/todo/todo.repository";
import { TodoTagType } from "data-access/repositories/todo_tag_type/todo/todo_tag_type.dto";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { convertTodoTagToJapanese } from "utils/convertTodoTagType";
import { handleReduxError } from "utils/errorHandler";
import { styles } from "./styles";

interface Props {
  isCreateNew: boolean;
  projectId?: ProjectId;
  currentPage: number;
}
export const ProjectSidebarTodoContent = memo((props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const cookie = cookieRepository.get();
  const [state, setState] = useState<ProjectTodoFormItem[]>([]);

  const { data: rawTodos, mutate: todosMutate } = useSWR(
    props.projectId ? API_PATHS.getProjectTodos(props.projectId) : null,
    () => projectTodoRepository.index(props.projectId as ProjectId),
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (rawTodos) {
      const todos = rawTodos.map((todo) => ({
        ...todo,
        is_edit_mode: false,
      }));
      setState(todos);
    }
  }, [rawTodos]);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isEdit = state?.some((todo) => todo.is_edit_mode);

  // 更新日時の降順で表示する
  const doneTodos = useMemo(
    () =>
      state
        ?.filter((todo) => todo.is_done)
        .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()) || [],
    [state],
  );
  const undoneTodos = useMemo(() => state?.filter((todo) => !todo.is_done) || [], [state]);

  const handleCreate = async () => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    try {
      const newTodoRequest: ProjectTodoRequest = {
        tagType: "schedule_adjustment",
        content: "",
      };
      await projectTodoRepository.create(props.projectId, newTodoRequest);
      todosMutate();
      mutate([API_PATHS.getProjects(), props.currentPage]);
      dispatch(mainOperations.updateSuccessMessage("TODOを作成しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "TODOの作成に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleCheck = async (e: React.ChangeEvent<HTMLInputElement>, todoId: ProjectTodoId) => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));
    const isDone = e.target.checked;

    try {
      if (isDone) {
        await projectTodoRepository.done(props.projectId, todoId);
        setState((prev) =>
          prev.map((todo) => {
            if (todo.id === todoId) {
              return {
                ...todo,
                is_done: isDone,
                completed_by: { id: cookie.id, name: cookie.name },
              };
            } else {
              return todo;
            }
          }),
        );
      } else {
        await projectTodoRepository.undone(props.projectId, todoId);
        setState((prev) => {
          const updatedTodos = prev.map((prev) => {
            if (prev.id === todoId) {
              return {
                ...prev,
                is_done: isDone,
                completed_by: { id: null, name: null },
              };
            } else {
              return prev;
            }
          });
          return updatedTodos;
        });
      }
      dispatch(mainOperations.updateSuccessMessage("TODOの状態を変更しました"));
      mutate([API_PATHS.getProjects(), props.currentPage]);
    } catch (error) {
      setState((prev) => {
        const updatedTodos = prev.map((todo) => {
          if (todo.id === todoId) {
            return {
              ...todo,
              is_done: !isDone,
            };
          } else {
            return todo;
          }
        });
        return updatedTodos;
      });
      handleReduxError(error, dispatch, "TODOの状態変更に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
    todoId: ProjectTodoId,
  ) => {
    if (!props.projectId) return;
    const updateRequest: ProjectTodoRequest = {};

    if (e.target.name === "tagType") {
      dispatch(mainOperations.updateIsLoading(true));
      updateRequest.tagType = e.target.value;
      setState((prev) =>
        prev.map((todo) => {
          if (todo.id === todoId) {
            return {
              ...todo,
              tag_type: {
                value: e.target.value,
                value_i18n: convertTodoTagToJapanese(e.target.value),
              },
            };
          } else {
            return todo;
          }
        }),
      );
    } else if (e.target.name === "content") {
      setState((prev) =>
        prev.map((todo) => {
          if (todo.id === todoId) {
            return {
              ...todo,
              content: e.target.value,
            };
          } else {
            return todo;
          }
        }),
      );
      return;
    }

    try {
      await projectTodoRepository.update(props.projectId, todoId, updateRequest);
      mutate([API_PATHS.getProjects(), props.currentPage]);
      dispatch(mainOperations.updateSuccessMessage("TODOを更新しました"));
    } catch (error) {
      setState((prev) =>
        prev.map((todo) => {
          if (todo.id === todoId) {
            return {
              ...todo,
              tag_type: { ...todo.tag_type, value: todo.tag_type.value },
            };
          } else {
            return todo;
          }
        }),
      );
      handleReduxError(error, dispatch, "TODOの更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const handleClickEditModeChange = (todoId: ProjectTodoId, value: boolean) => {
    setState((prev) =>
      prev.map((todo) => {
        if (todo.id === todoId) {
          return {
            ...todo,
            is_edit_mode: value,
          };
        } else {
          return todo;
        }
      }),
    );
  };

  const handleBlur = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    todoId: ProjectTodoId,
  ) => {
    if (!props.projectId) return;
    dispatch(mainOperations.updateIsLoading(true));

    try {
      await projectTodoRepository.update(props.projectId, todoId, {
        content: e.target.value,
      });
      setState((prev) =>
        prev.map((todo) => {
          if (todo.id === todoId) {
            return {
              ...todo,
              is_edit_mode: false,
              content: e.target.value,
            };
          } else {
            return todo;
          }
        }),
      );
      mutate([API_PATHS.getProjects(), props.currentPage]);
      dispatch(mainOperations.updateSuccessMessage("TODOを更新しました"));
    } catch (error) {
      setState((prev) =>
        prev.map((todo) => {
          if (todo.id === todoId) {
            return {
              ...todo,
              content: todo.content,
            };
          } else {
            return todo;
          }
        }),
      );
      handleReduxError(error, dispatch, "TODOの更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (todoId: ProjectTodoId) => {
    if (!deleteConfirmRef.current || !props.projectId) return;
    const res = await deleteConfirmRef.current.confirm();
    if (!res) return;

    dispatch(mainOperations.updateIsLoading(true));
    try {
      await projectTodoRepository.destroy(props.projectId, todoId);
      setState((prev) => prev.filter((todo) => todo.id !== todoId));
      mutate([API_PATHS.getProjects(), props.currentPage]);
      dispatch(mainOperations.updateSuccessMessage("TODOを削除しました"));
    } catch (error) {
      handleReduxError(error, dispatch, "TODOの削除に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />

      <div style={{ marginBottom: "24px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "8px",
          }}
        >
          <CustomFormLabel labelName="TODO" sx={{ mb: 0 }} />
          <Button
            variant="contained"
            onClick={handleCreate}
            disabled={props.isCreateNew || isEdit}
            sx={{ height: "34px", borderRadius: "16px", py: 1, px: 2, fontSize: "12px" }}
          >
            TODOを追加
          </Button>
        </Box>

        {/* 未完了のTodos */}
        {undoneTodos.length === 0 && (
          <Typography sx={{ fontSize: "14px" }}>TODOはありません</Typography>
        )}
        {undoneTodos.map((undoneTodo) => (
          <Box
            key={undoneTodo.id}
            sx={{
              display: "flex",
              border: `1px solid ${theme.palette.grayScale[300]}`,
              borderRadius: "4px",
              position: "relative",
              p: "12px",
              mb: "12px",
            }}
          >
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isTodoDone"
                    sx={{ p: 0 }}
                    onChange={(e) => handleCheck(e, undoneTodo.id)}
                    checked={undoneTodo.is_done}
                  />
                }
                label={
                  <Typography component="span" sx={{ fontSize: "11px" }}>
                    完了
                  </Typography>
                }
                labelPlacement="top"
                sx={{ ...styles.checkbox }}
              />
              <Box sx={{ width: "100%", ml: "11px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "40px",
                    alignItems: "center",
                    mb: "5px",
                  }}
                >
                  <Select
                    name="tagType"
                    variant="standard"
                    value={undoneTodo.tag_type.value}
                    onChange={(e) => handleChange(e, undoneTodo.id)}
                    sx={{ ...styles.selectBox }}
                  >
                    {mainState.projectTodoTagTypes.map((tagType: TodoTagType) => (
                      <MenuItem key={tagType.value} value={tagType.value}>
                        <TodoTagTypeLabel tagType={tagType.value_i18n} />
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton onClick={() => handleDelete(undoneTodo.id)} sx={{ p: 0 }}>
                    <DeleteIcon color={theme.palette.grayScale[700]} size={20} />
                  </IconButton>
                </Box>
                {undoneTodo.is_edit_mode ? (
                  <TextField
                    name="content"
                    value={undoneTodo.content}
                    onChange={(e) => handleChange(e, undoneTodo.id)}
                    onBlur={(e) => handleBlur(e, undoneTodo.id)}
                    placeholder="詳細メモを入力"
                    autoFocus
                    multiline
                    slotProps={{
                      htmlInput: { style: { fontSize: 14 } },
                    }}
                    sx={{ width: "100%", "& .MuiInputBase-root": { p: "8px" } }}
                  />
                ) : (
                  <Box onClick={() => handleClickEditModeChange(undoneTodo.id, true)}>
                    {undoneTodo.content.length === 0 ? (
                      <Typography
                        fontSize={"12px"}
                        sx={{ mb: "5px", color: theme.palette.grayScale[700] }}
                      >
                        詳細メモを入力
                      </Typography>
                    ) : (
                      <Typography
                        fontSize={"12px"}
                        sx={{
                          mb: "5px",
                          cursor: "pointer",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          display: "-webkit-box",
                          "-webkit-box-orient": "vertical",
                          "-webkit-line-clamp": "2",
                        }}
                      >
                        {undoneTodo.content}
                      </Typography>
                    )}
                  </Box>
                )}
                <Typography fontSize={"11px"} sx={{ mt: "8px", textAlign: "right" }}>
                  作成:{undoneTodo.created_by?.name}
                </Typography>
              </Box>
            </>
          </Box>
        ))}
      </div>

      {/* 完了済みのTodos */}
      <AccordionToggle
        title="完了済みのTODO"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isDisabled={doneTodos.length === 0}
        titleStyle={{ fontWeight: "500", color: theme.palette.grayScale[700] }}
      />
      {doneTodos.length === 0 && (
        <Typography sx={{ fontSize: "14px", mt: "8px" }}>完了済みのTODOはありません</Typography>
      )}
      {isExpanded &&
        doneTodos.map((doneTodo) => (
          <Box
            key={doneTodo.id}
            sx={{
              display: "flex",
              border: `1px solid ${theme.palette.grayScale[300]}`,
              borderRadius: "4px",
              p: "12px",
              my: "12px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="isTodoUndone"
                  sx={{
                    p: 0,
                    "&.Mui-checked": {
                      color: theme.palette.grayScale[700],
                    },
                  }}
                  onChange={(e) => handleCheck(e, doneTodo.id)}
                  checked={doneTodo.is_done}
                />
              }
              label={
                <Typography component="span" sx={{ fontSize: "11px" }}>
                  完了
                </Typography>
              }
              labelPlacement="top"
              sx={{ ...styles.checkbox }}
            />
            <Box sx={{ width: "100%", ml: "11px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "40px",
                  alignItems: "center",
                  mb: "5px",
                }}
              >
                <TodoTagTypeLabel
                  tagType={doneTodo.tag_type.value_i18n}
                  sx={{
                    backgroundColor: theme.palette.grayScale[100],
                    color: theme.palette.grayScale[700],
                  }}
                />
              </Box>
              <Typography fontSize={"12px"} sx={{ mb: "5px" }}>
                <s>{doneTodo.content}</s>
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Typography sx={{ fontSize: "11px", mr: "16px" }}>
                  作成：{doneTodo.created_by?.name}
                </Typography>
                <Typography fontSize={"11px"}>完了：{doneTodo.completed_by?.name}</Typography>
              </Box>
            </Box>
          </Box>
        ))}
    </>
  );
});
