import { useState } from "react";
import FaxIcon from "@mui/icons-material/Fax";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Box,
  Button,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { addressRepository } from "data-access/repositories/address/address.repository";
import { ClientId, ClientRequest } from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { postalCodeRepository } from "data-access/repositories/postal_code/postal_code.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";

const CustomInputLabel = styled(InputLabel)`
  margin-bottom: 4px;
`;

const STypography = styled(Typography)({
  color: theme.palette.grayScale[700],
  fontSize: "14px",
  display: "flex",
  gap: "8px",
});

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (e: SelectChangeEvent<string>) => void;
  onBlur: () => void;
  formState: ClientRequest;
  setFormState: React.Dispatch<React.SetStateAction<ClientRequest>>;
  clientId: ClientId;
}
export const ClientSidebarInfo = (props: Props) => {
  const { onChange, onSelect, onBlur, formState, setFormState, clientId } = props;
  const dispatch = useAppDispatch();
  const [searchedAddress, setSearchedAddress] = useState<string>("");
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string>("");
  const [addressErrorMessage, setAddressErrorMessage] = useState<string>("");

  const handleClickSearchPostalCode = async () => {
    if (!formState.address) return;
    try {
      const res = await postalCodeRepository.index(formState.address);
      setSearchedAddress(
        res.data.length ? res.data[0].prefecture + res.data[0].city + res.data[0].town : "",
      );
      try {
        await clientRepository.update(clientId, {
          postalCode: res.data[0].postal_code,
        });
        setFormState({ ...formState, postalCode: res.data[0].postal_code });
        dispatch(mainOperations.updateSuccessMessage("顧客を更新しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
      setAddressErrorMessage("");
      dispatch(mainOperations.updateErrorMessage(""));
    } catch {
      setSearchedAddress("");
      setAddressErrorMessage("入力した住所から郵便番号が見つかりませんでした");
    }
  };

  const handleClickSearchAddress = async () => {
    if (!formState.postalCode) return;
    try {
      const postalCode: string = formState.postalCode.replace(/-/g, "");
      const res = await addressRepository.index(postalCode);
      const address = res.data[0].prefecture + res.data[0].city + res.data[0].town;

      setPostalCodeErrorMessage("");
      dispatch(mainOperations.updateErrorMessage(""));
      try {
        await clientRepository.update(clientId, {
          address,
        });
        setFormState({ ...formState, address });
        dispatch(mainOperations.updateSuccessMessage("顧客を更新しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    } catch {
      setPostalCodeErrorMessage("入力した郵便番号から住所が見つかりませんでした");
    }
  };

  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
          <div style={{ width: "450px" }}>
            <CustomInputLabel>顧客名(カナ)</CustomInputLabel>
            <TextField
              id="nameKana"
              name="nameKana"
              value={formState.nameKana || ""}
              onChange={onChange}
              onBlur={onBlur}
              fullWidth
              placeholder="顧客名を入力"
            />
          </div>
          <div style={{ width: "300px" }}>
            <CustomInputLabel>会計ソフトコード</CustomInputLabel>
            <TextField
              id="code"
              name="code"
              value={formState.code || ""}
              onChange={onChange}
              onBlur={onBlur}
              fullWidth
              placeholder="会計ソフトで使用するコードを入力"
            />
          </div>
          <div style={{ width: "200px" }}>
            <CustomInputLabel>請求締日</CustomInputLabel>
            <Select
              id="billingClosingDateType"
              name="billingClosingDateType"
              value={formState.billingClosingDateType || ""}
              onChange={onSelect}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                請求締日を選択
              </MenuItem>
              <MenuItem value="not_yet_set">未設定</MenuItem>
              <MenuItem value="last_day">末日</MenuItem>
              <MenuItem value="fifth">5日</MenuItem>
              <MenuItem value="tenth">10日</MenuItem>
              <MenuItem value="fifteenth">15日</MenuItem>
              <MenuItem value="twentieth">20日</MenuItem>
              <MenuItem value="twenty_fifth">25日</MenuItem>
            </Select>
          </div>
        </div>
        <Box sx={{ display: "flex", gap: "16px", mb: "24px" }}>
          <Box sx={{ width: "100%" }}>
            <CustomInputLabel>入金月</CustomInputLabel>
            <Select
              id="depositMonthType"
              name="depositMonthType"
              value={formState.depositMonthType || ""}
              onChange={onSelect}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                入金月を選択
              </MenuItem>
              <MenuItem value="not_yet_set">未設定</MenuItem>
              <MenuItem value="this_month">当月</MenuItem>
              <MenuItem value="next_month">翌月</MenuItem>
              <MenuItem value="two_month_later">翌々月</MenuItem>
              <MenuItem value="three_month_later">3ヶ月後</MenuItem>
            </Select>
          </Box>
          <Box sx={{ width: "100%" }}>
            <CustomInputLabel>入金日</CustomInputLabel>
            <Select
              id="depositDateType"
              name="depositDateType"
              value={formState.depositDateType || ""}
              onChange={onSelect}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                入金日を選択
              </MenuItem>
              <MenuItem value="not_yet_set">未設定</MenuItem>
              <MenuItem value="last_day">末日</MenuItem>
              <MenuItem value="fifth">5日</MenuItem>
              <MenuItem value="tenth">10日</MenuItem>
              <MenuItem value="fifteenth">15日</MenuItem>
              <MenuItem value="twentieth">20日</MenuItem>
              <MenuItem value="twenty_fifth">25日</MenuItem>
            </Select>
          </Box>
          <Box sx={{ width: "100%" }}>
            <CustomInputLabel>入金方法</CustomInputLabel>
            <Select
              id="depositMethodType"
              name="depositMethodType"
              value={formState.depositMethodType || ""}
              onChange={onSelect}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                入金方法を選択
              </MenuItem>
              <MenuItem value="not_yet_set">未設定</MenuItem>
              <MenuItem value="bank_transfer">銀行振込</MenuItem>
              <MenuItem value="promissory_note">手形</MenuItem>
              <MenuItem value="cash">現金</MenuItem>
              <MenuItem value="half_cash_half_note">半金半手</MenuItem>
              <MenuItem value="credit_card">クレジットカード</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box sx={{ mb: "24px" }}>
          <CustomInputLabel>注意事項</CustomInputLabel>
          <TextField
            id="note"
            name="note"
            value={formState.note || ""}
            onChange={onChange}
            onBlur={onBlur}
            multiline
            rows={4}
            sx={{ width: "100%", mt: "0.5rem" }}
            placeholder="注意事項を入力"
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
          gap: "16px",
        }}
      >
        <div style={{ width: "100%" }}>
          <CustomInputLabel>部署名</CustomInputLabel>
          <TextField
            id="billingPersonSection"
            name="billingPersonSection"
            value={formState.billingPersonSection || ""}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
            placeholder="部署名を入力"
          />
        </div>
        <div style={{ width: "100%" }}>
          <CustomInputLabel>役職名</CustomInputLabel>
          <TextField
            id="billingPersonPosition"
            name="billingPersonPosition"
            value={formState.billingPersonPosition || ""}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
            placeholder="役職名を入力"
          />
        </div>
        <div style={{ width: "100%" }}>
          <CustomInputLabel>担当者氏名</CustomInputLabel>
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <TextField
              id="billingPersonName"
              name="billingPersonName"
              value={formState.billingPersonName || ""}
              onChange={onChange}
              onBlur={onBlur}
              fullWidth
              placeholder="担当者氏名を入力"
              InputProps={{
                endAdornment: <InputAdornment position="start">様</InputAdornment>,
              }}
            />
          </Box>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
          gap: "16px",
        }}
      >
        <div>
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <CustomInputLabel>郵便番号</CustomInputLabel>
            <Button
              onClick={handleClickSearchAddress}
              disabled={!formState.postalCode}
              sx={{ pt: 0 }}
            >
              住所を検索
            </Button>
          </Box>
          <TextField
            id="postalCode"
            name="postalCode"
            value={formState.postalCode || ""}
            error={addressErrorMessage.length > 0}
            helperText={addressErrorMessage}
            onChange={onChange}
            onBlur={onBlur}
            InputProps={{
              startAdornment: <InputAdornment position="start">〒</InputAdornment>,
            }}
            sx={{ width: "170px" }}
          />
          {searchedAddress && (
            <Typography sx={{ position: "relative", top: "3px" }}>
              検索結果: {searchedAddress}
            </Typography>
          )}
        </div>
        <div style={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <CustomInputLabel>住所</CustomInputLabel>
            <Button
              onClick={handleClickSearchPostalCode}
              disabled={!formState.address}
              sx={{ pt: 0 }}
            >
              郵便番号を検索
            </Button>
          </Box>
          <TextField
            id="address"
            name="address"
            value={formState.address || ""}
            error={postalCodeErrorMessage.length > 0}
            helperText={postalCodeErrorMessage}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="東京都渋谷区神泉町8-1"
            fullWidth
          />
        </div>
        <div style={{ width: "100%" }}>
          <CustomInputLabel>建物名・階・号室</CustomInputLabel>
          <TextField
            id="addressSecond"
            name="addressSecond"
            value={formState.addressSecond || ""}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="フォーラム渋谷神泉7階"
            fullWidth
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
          gap: "16px",
        }}
      >
        <Box sx={{ mb: "24px" }}>
          <STypography>
            <PhoneIcon sx={{ width: "18px" }} />
            電話番号1
          </STypography>
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            value={formState.phoneNumber || ""}
            onChange={onChange}
            placeholder="000-0000-0000"
            onBlur={onBlur}
          />
        </Box>
        <Box sx={{ mb: "24px" }}>
          <STypography>
            <PhoneIcon sx={{ width: "18px" }} />
            電話番号2
          </STypography>
          <TextField
            id="phoneNumberSecond"
            name="phoneNumberSecond"
            value={formState.phoneNumberSecond || ""}
            onChange={onChange}
            placeholder="000-0000-0000"
            onBlur={onBlur}
          />
        </Box>
        <div>
          <STypography>
            <FaxIcon sx={{ width: "18px" }} />
            FAX番号
          </STypography>
          <TextField
            id="faxNumber"
            name="faxNumber"
            value={formState.faxNumber || ""}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="000-0000-0000"
          />
        </div>
        <div>
          <STypography>
            <MailOutlineIcon sx={{ width: "18px" }} />
            メールアドレス
          </STypography>
          <TextField
            id="email"
            name="email"
            value={formState.email || ""}
            onChange={onChange}
            placeholder="XXX@XXX.com"
            onBlur={onBlur}
            sx={{ width: "400px" }}
          />
        </div>
      </div>
    </>
  );
};
