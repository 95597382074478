import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Step, StepLabel, Stepper } from "@mui/material";
import { CustomModal } from "components/molecules/custom-modal";
import { PhotoResponse, ProjectPhotoId } from "data-access/repositories/project/photo/photo.dto";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { WorkReportPhotoId } from "data-access/repositories/work_report/photo/photo.dto";
import { theme } from "extensions/theme";
import { clientServiceReportRepository } from "features/client-service-report/api/client_service_report.repository";
import { clientServiceReportFormatRepository } from "features/client-service-report/api/client_service_report_format.repository";
import { clientServiceReportItemRepository } from "features/client-service-report/api/client_service_report_item.repository";
import { clientServiceReportPageRepository } from "features/client-service-report/api/client_service_report_page.repository";
import {
  ClientServiceReportResponse,
  initialClientServiceReportResponse,
} from "features/client-service-report/types/client_service_report.dto";
import { ClientServiceReportFormatId } from "features/client-service-report/types/client_service_report_format.dto";
import {
  ClientServiceReportItemRequest,
  ClientServiceReportItemResponse,
} from "features/client-service-report/types/client_service_report_item.dto";
import { ClientServiceReportPageId } from "features/client-service-report/types/client_service_report_page.dto";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { mutate } from "swr";
import { handleReduxError } from "utils/errorHandler";
import { formatDateUtil } from "utils/formatDateUtil";
import { FormatSelect } from "../../format-select";
import { FormatPageArea } from "../../page";
import { ConfirmDialog } from "../confirm-dialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: ProjectId;
  fetchIndexKey: string;
}
export const CreateModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [steps, setSteps] = useState<string[]>(["書式"]);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<{ isOpen: boolean; type: "close" | "change" }>({
    isOpen: false,
    type: "close",
  });
  const [selectedLayoutId, setSelectedLayoutId] = useState<ClientServiceReportFormatId>(
    0 as ClientServiceReportFormatId,
  );
  // 全体描画に使用
  const [clientServiceReport, setClientServiceReport] = useState<ClientServiceReportResponse>(
    initialClientServiceReportResponse,
  );
  /** 全体描画しない時の一時的な状態として使用（Itemのvalue編集時など）
   *
   *  なんかしらの状態を更新する際はまずこちらを更新し、その後必要あらばclientServiceReportに反映する
   *
   *  （clientServiceReportの更新は全体描画を伴うため、毎回行うとパフォーマンスが悪い場面では更新を避ける必要あり）
   */
  const clientServiceReportRef = useRef<ClientServiceReportResponse>(
    initialClientServiceReportResponse,
  );
  const setClientServiceReportRef = (data: ClientServiceReportResponse) => {
    clientServiceReportRef.current = data;
  };

  const { data: formats, isLoading: isFormatsLoading } = useSWR(
    props.isOpen ? "/api/v1/companies/client_service_report_formats" : null,
    () => clientServiceReportFormatRepository.index(),
  );

  // ステップバーの設定
  useEffect(() => {
    if (props.isOpen && formats && formats.length) {
      setSelectedLayoutId(formats[0].id);
      setSteps([
        "書式",
        ...formats[0].pages.map((page) => {
          return page.excelSheetName;
        }),
      ]);
    }
  }, [props.isOpen, formats]);

  const handleSelectLayout = async () => {
    // 報告書作成後に異なるレイアウトを選択したとき
    if (clientServiceReport.id !== 0 && selectedLayoutId !== clientServiceReport.format.id) {
      setConfirmModal({ isOpen: true, type: "change" });
      return;
    }

    setCurrentStepIndex(currentStepIndex + 1);
    if (selectedLayoutId === clientServiceReport.format.id) return;

    if (clientServiceReport.id === 0) {
      setIsLoading(true);
      try {
        const res = await clientServiceReportRepository.create({
          ClientServiceReportFormatId: selectedLayoutId,
          projectId: props.projectId,
        });
        setClientServiceReportRef(res);
        setClientServiceReport(res);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // 書式選択後に、別の書式を選択したとき
  const handleChangeLayout = async () => {
    setIsLoading(true);
    try {
      const res = await clientServiceReportRepository.update(
        clientServiceReport.id,
        selectedLayoutId,
      );
      setClientServiceReportRef(res);
      setClientServiceReport(res);
      setCurrentStepIndex(currentStepIndex + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    setConfirmModal({ isOpen: false, type: "change" });
  };

  /** @return Itemの変更を反映したClientServiceReportResponse */
  const setItemToClientServiceReportRef = (newItem: ClientServiceReportItemResponse) => {
    setClientServiceReportRef({
      ...clientServiceReportRef.current,
      formatPages: clientServiceReportRef.current.formatPages.map((formatPage) => {
        return {
          ...formatPage,
          pages: formatPage.pages.map((page) => ({
            ...page,
            headings: page.headings.map((heading) => ({
              ...heading,
              items: heading.items.map((item) => {
                if (newItem.id === item.id) {
                  return newItem;
                }
                return item;
              }),
            })),
          })),
        };
      }),
    });
  };

  /**
   * TextField系のvalue変更. 変更ごとに呼ばれるため、その度に全体描画を行わないようにする.
   *
   * (currentStepIndex変更時などにclientServiceReportに反映)
   */
  const handleChangeItemValue = (newItem: ClientServiceReportItemResponse) => {
    setItemToClientServiceReportRef(newItem);
  };

  /** 「写真を選択」クリック時、更新するItemを保持 */
  const [selectedPhotoAreaItem, setSelectedPhotoAreaItem] =
    useState<ClientServiceReportItemResponse | null>(null);
  const handlePhotoAreaClick = (item: ClientServiceReportItemResponse) => {
    setSelectedPhotoAreaItem(item);
  };

  const handlePhotoSelect = (photo: PhotoResponse): void => {
    if (selectedPhotoAreaItem === null) return;

    const isProjectPhoto = photo.projectAttributes !== null;
    const isWorkReportPhoto = photo.workReportAttributes !== null;

    setItemToClientServiceReportRef({
      ...selectedPhotoAreaItem,
      value: photo.thumbnailUrl,
      photo: isProjectPhoto ? photo : null,
      workReportPhoto: isWorkReportPhoto ? photo : null,
    });
    setClientServiceReport(clientServiceReportRef.current);
  };

  const handlePhotoDelete = (selectedItem: ClientServiceReportItemResponse): void => {
    setItemToClientServiceReportRef({
      ...selectedItem,
      value: "",
      photo: null,
      workReportPhoto: null,
    });
    setClientServiceReport(clientServiceReportRef.current);
  };

  const handleAddPage = async (formatPageId: number) => {
    setIsLoading(true);
    try {
      const res = await clientServiceReportPageRepository.create(clientServiceReport.id, {
        clientServiceReportFormatPageId: clientServiceReport.formatPages.filter(
          (formatPage) => formatPage.id === formatPageId,
        )[0].id,
      });
      const newReport = {
        ...clientServiceReportRef.current,
        formatPages: clientServiceReportRef.current.formatPages.map((formatPage) => {
          if (formatPage.id === formatPageId) {
            return { ...formatPage, pages: formatPage.pages.concat(res) };
          }
          return formatPage;
        }),
      };
      setClientServiceReportRef(newReport);
      setClientServiceReport(newReport);
    } catch (error) {
      handleReduxError(error, dispatch, "ページの追加に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePage = async (pageId: ClientServiceReportPageId) => {
    setIsLoading(true);
    try {
      await clientServiceReportPageRepository.destroy(clientServiceReport.id, pageId);
      const newReport = {
        ...clientServiceReportRef.current,
        formatPages: clientServiceReportRef.current.formatPages.map((formatPage) => {
          return {
            ...formatPage,
            pages: formatPage.pages.filter((page) => page.id !== pageId),
          };
        }),
      };
      setClientServiceReportRef(newReport);
      setClientServiceReport(newReport);
    } catch (error) {
      handleReduxError(error, dispatch, "ページの削除に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const converted: ClientServiceReportItemRequest = {
      values: clientServiceReportRef.current.formatPages.flatMap((formatPage) =>
        formatPage.pages.flatMap((page) =>
          page.headings.flatMap((heading) =>
            heading.items.flatMap((item) => {
              let value = item.value;
              // 日付はISO8601形式のため、yyyy-MM-dd形式に変換する
              if (
                typeof value === "string" &&
                value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)
              ) {
                value = formatDateUtil(value, "yyyy-MM-dd");
              }
              return {
                id: item.id,
                value: !item.photo && !item.workReportPhoto ? value : "",
                photoId: item.photo ? (item.photo.id as ProjectPhotoId) : null,
                workReportPhotoId: item.workReportPhoto
                  ? (item.workReportPhoto.id as WorkReportPhotoId)
                  : null,
              };
            }),
          ),
        ),
      ),
    };
    setIsLoading(true);
    try {
      await clientServiceReportItemRepository.bulk(converted);
      mutate(props.fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("報告書を保存しました"));
      props.onClose();
      setCurrentStepIndex(0);
      setClientServiceReport(initialClientServiceReportResponse);
    } catch (error) {
      handleReduxError(error, dispatch, "報告書の保存に失敗しました");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectIndex = (index: number) => {
    // refの状態を描画に反映
    setClientServiceReport(clientServiceReportRef.current);
    setCurrentStepIndex(index);
  };

  const handleClose = async () => {
    props.onClose();
    setCurrentStepIndex(0);
    setClientServiceReportRef(initialClientServiceReportResponse);
    setClientServiceReport(initialClientServiceReportResponse);
    setConfirmModal({ isOpen: false, type: "close" });
    if (clientServiceReport.id !== 0) {
      setIsLoading(true);
      try {
        await clientServiceReportRepository.destroy(clientServiceReport.id);
        setClientServiceReportRef(initialClientServiceReportResponse);
        setClientServiceReport(initialClientServiceReportResponse);
        mutate(props.fetchIndexKey);
      } catch {
        console.error("削除失敗");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <ConfirmDialog
        type={confirmModal.type}
        isOpen={confirmModal.isOpen}
        handleClose={() => setConfirmModal({ isOpen: false, type: "close" })}
        handleYesChange={handleChangeLayout}
        handleYesClose={handleClose}
      />

      <CustomModal
        open={props.isOpen}
        onClose={
          clientServiceReport.id === 0
            ? handleClose
            : () => setConfirmModal({ isOpen: true, type: "close" })
        }
        title="報告書の作成"
        minHeight="70vh"
        maxWidth="lg"
        footer={
          <div style={{ display: "flex", gap: "16px" }}>
            {currentStepIndex === 1 && (
              <Button
                onClick={() => handleSelectIndex(0)}
                variant="outlined"
                sx={{ width: "184px" }}
              >
                書式選択に戻る
              </Button>
            )}
            {currentStepIndex > 1 && (
              <Button
                onClick={() => handleSelectIndex(currentStepIndex - 1)}
                variant="outlined"
                sx={{ width: "184px" }}
              >
                戻る
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={
                clientServiceReport.id === 0
                  ? handleClose
                  : () => setConfirmModal({ isOpen: true, type: "close" })
              }
              sx={{ width: "184px" }}
            >
              キャンセル
            </Button>
            {currentStepIndex === 0 && (
              <Button onClick={handleSelectLayout} variant="contained" sx={{ width: "184px" }}>
                この書式を使用
              </Button>
            )}
            {currentStepIndex > 0 && currentStepIndex < clientServiceReport.formatPages.length && (
              <Button
                onClick={() => handleSelectIndex(currentStepIndex + 1)}
                variant="contained"
                sx={{ width: "184px" }}
              >
                次へ
              </Button>
            )}
            {currentStepIndex !== 0 &&
              currentStepIndex === clientServiceReport.formatPages.length && (
                <Button onClick={handleSubmit} variant="contained" sx={{ width: "184px" }}>
                  この内容で保存
                </Button>
              )}
          </div>
        }
      >
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={isLoading || isFormatsLoading}
          invisible
        >
          <CircularProgress />
        </Backdrop>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: "24px",
          }}
        >
          <Stepper activeStep={currentStepIndex} alternativeLabel sx={{ width: "400px" }}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <div>
          {currentStepIndex === 0 && (
            <FormatSelect
              formats={formats || []}
              isLoading={isFormatsLoading}
              selectedLayoutId={selectedLayoutId}
              selectedLayoutIdSetter={setSelectedLayoutId}
              stepsSetter={setSteps}
            />
          )}
          {clientServiceReport.formatPages.map((formatPage, index) => (
            <React.Fragment key={index}>
              {currentStepIndex === index + 1 && (
                <FormatPageArea
                  projectId={props.projectId}
                  currentStepIndex={currentStepIndex}
                  selectedLayoutName={clientServiceReport.format.name}
                  formatPage={formatPage}
                  onChange={handleChangeItemValue}
                  onAddPage={handleAddPage}
                  onDelete={handleDeletePage}
                  onClick={handlePhotoAreaClick}
                  onSelect={handlePhotoSelect}
                  onPhotoDelete={handlePhotoDelete}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </CustomModal>
    </>
  );
};
