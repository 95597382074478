import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { GroupLabel } from "components/label/group-label";
import { PhotoDropzone } from "components/molecules/photo-dropzone";
import { PhotoPreviewModal } from "components/organisms/photo-preview-modal";
import { PhotoResponse } from "data-access/repositories/project/photo/photo.dto";
import { photoRepository as projectPhotoRepository } from "data-access/repositories/project/photo/photo.repository";
import {
  PrimaryCategory,
  PrimaryCategoryId,
} from "data-access/repositories/project/primary_category/primary_category.dto";
import { primaryCategoryRepository } from "data-access/repositories/project/primary_category/primary_category.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { SecondaryCategoryId } from "data-access/repositories/project/secondary_category/secondary_category.dto";
import { secondaryCategoryRepository } from "data-access/repositories/project/secondary_category/secondary_category.repository";
import { TertiaryCategoryId } from "data-access/repositories/project/tertiary_category/tertiary_category.dto";
import { theme } from "extensions/theme";
import { usePhotoPreview } from "hooks/usePhotoPreview";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: ProjectId;
  onSelect: (photo: PhotoResponse) => void;
}
export const PhotoSelectModal = (props: Props) => {
  const { isOpen, onClose } = props;
  const classes = styles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      scroll="paper"
      className={classes.modal}
    >
      <DialogTitle className={classes.title}>
        写真を選択
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <PhotosBlock {...props} />
      </DialogContent>
    </Dialog>
  );
};

const PhotosBlock = (props: Props) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  const [selectedPrimaryCategoryId, setSelectedPrimaryCategoryId] = useState<PrimaryCategoryId>(
    0 as PrimaryCategoryId,
  );
  const [selectedSecondaryCategoryId, setSelectedSecondaryCategoryId] =
    useState<SecondaryCategoryId>(0 as SecondaryCategoryId);

  const { data: primaryCategories } = useSWR(
    `/api/v1/projects/${props.projectId}/primary_categories`,
    () => primaryCategoryRepository.index(props.projectId),
    { revalidateOnFocus: false },
  );

  const { data: secondaryCategory } = useSWR(
    selectedPrimaryCategoryId &&
      selectedSecondaryCategoryId &&
      `/api/v1/projects/${props.projectId}/secondary_categories/${selectedSecondaryCategoryId}`,
    () =>
      secondaryCategoryRepository.show(
        props.projectId,
        selectedPrimaryCategoryId,
        selectedSecondaryCategoryId,
      ),
    { revalidateOnFocus: false },
  );

  const { data: photos, mutate: photoMutate } = useSWR(
    selectedPrimaryCategoryId &&
      selectedSecondaryCategoryId &&
      `/api/v1/projects/${props.projectId}/primary_categories/${selectedPrimaryCategoryId}/secondary_categories/${selectedSecondaryCategoryId}/photos`,
    () =>
      secondaryCategoryRepository.getPhotos(
        props.projectId,
        selectedPrimaryCategoryId,
        selectedSecondaryCategoryId,
      ),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (primaryCategories) {
      setSelectedPrimaryCategoryId(primaryCategories[0].id);
      setSelectedSecondaryCategoryId(primaryCategories[0].secondaryCategories[0].id);
    }
  }, [primaryCategories]);

  const { photoPreviewState, setPhotoPreviewState, handleClickPhotoPreview } =
    usePhotoPreview(photos);

  const handleSelectPrimaryCategory = (primaryCategory: PrimaryCategory) => {
    setSelectedPrimaryCategoryId(primaryCategory.id);
    setSelectedSecondaryCategoryId(primaryCategory.secondaryCategories[0].id);
  };

  const handleSelectSecondaryCategory = (
    primaryCategoryId: PrimaryCategoryId,
    secondaryCategoryId: SecondaryCategoryId,
  ) => {
    setSelectedPrimaryCategoryId(primaryCategoryId);
    setSelectedSecondaryCategoryId(secondaryCategoryId);
  };

  const handlePhotoUpload = async (
    files: File[] | FileList | null,
    tertiaryCategoryId: TertiaryCategoryId,
  ) => {
    if (files) {
      const requestObj: {
        tertiaryCategoryId: TertiaryCategoryId;
        files: File[];
        capturedAt: (Date | null)[];
      } = {
        tertiaryCategoryId,
        files: [],
        capturedAt: [],
      };
      for (let i = 0; i < files.length; i++) {
        const targetPhoto = files[i];
        const photo = new File([targetPhoto], targetPhoto.name, {
          lastModified: targetPhoto.lastModified,
        });
        requestObj.files.push(photo);
      }
      dispatch(mainOperations.updateIsLoading(true));
      try {
        const response = await projectPhotoRepository.create(props.projectId, requestObj);
        const successfulUploads = response.filter(
          (photo) => photo.upload_status === "success",
        ).length;
        const successfulUploadNumber = response.filter(
          (photo) => photo.upload_status === "success",
        ).length;
        dispatch(
          mainOperations.updateSuccessMessage(
            `${response.length}枚中、${successfulUploadNumber}枚の写真をアップロードしました`,
          ),
        );
        photoMutate();
        if (successfulUploads === 0) {
          dispatch(mainOperations.updateErrorMessage("全ての写真のアップロードに失敗しました"));
        }
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      } finally {
        dispatch(mainOperations.updateIsLoading(false));
      }
    }
  };

  if (!primaryCategories) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <PhotoPreviewModal state={photoPreviewState} setState={setPhotoPreviewState} />

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "180px", mr: "8px", overflow: "scroll", height: "75vh" }}>
          {primaryCategories.map((primaryCategory) => (
            <Box key={primaryCategory.id} sx={{ mb: "16px" }}>
              <Box
                onClick={() => handleSelectPrimaryCategory(primaryCategory)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "12px",
                  ":hover": { cursor: "pointer" },
                  color:
                    selectedPrimaryCategoryId === primaryCategory.id
                      ? theme.palette.primary.main
                      : "inherit",
                }}
              >
                {primaryCategory.name}
              </Box>
              {primaryCategory.secondaryCategories.map((secondaryCategory) => (
                <div key={secondaryCategory.id} style={{ marginLeft: "8px" }}>
                  <Box
                    onClick={() =>
                      handleSelectSecondaryCategory(primaryCategory.id, secondaryCategory.id)
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "bold",
                      mb: "12px",
                      ":hover": { cursor: "pointer" },
                      color:
                        selectedSecondaryCategoryId === secondaryCategory.id
                          ? theme.palette.primary.main
                          : theme.palette.grayScale[700],
                    }}
                  >
                    {secondaryCategory.name}
                  </Box>
                </div>
              ))}
            </Box>
          ))}
        </Box>

        {secondaryCategory && (
          <Box sx={{ width: "100%", overflow: "scroll", height: "75vh" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: "20px" }}>
              {secondaryCategory.name}
            </Typography>
            {secondaryCategory.tertiaryCategories.map((tertiaryCategory) => {
              const filteredPhotos = photos?.filter(
                (photo) => photo.tertiaryCategory.id === tertiaryCategory.id,
              );

              return (
                <React.Fragment key={tertiaryCategory.id}>
                  <GroupLabel
                    name={tertiaryCategory.name}
                    colorNumber={tertiaryCategory.colorNumber}
                  />
                  <Box className={classes.imageBoxBlock}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "24px",
                        mt: "16px",
                        mb: "28px",
                      }}
                    >
                      {filteredPhotos &&
                        filteredPhotos.map((photo) => (
                          <Box key={photo.id} className={classes.imageBox}>
                            <Box
                              className="buttonBox"
                              sx={{
                                display: "none",
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0,0,0,0.6)",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() => props.onSelect(photo)}
                                sx={{ width: "80%", mb: "16px" }}
                              >
                                この写真を選択
                              </Button>
                              <Button
                                variant="outlined"
                                onClick={() => handleClickPhotoPreview(photo)}
                                sx={{
                                  width: "80%",
                                  backgroundColor: theme.palette.grayScale[0],
                                  "&:hover": {
                                    backgroundColor: theme.palette.grayScale[200],
                                  },
                                }}
                              >
                                プレビュー
                              </Button>
                            </Box>
                            <Box
                              sx={{
                                borderBottom: `1px solid ${theme.palette.grayScale[300]}`,
                                height: "137px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                alt={`photo-${photo.id}`}
                                src={photo.thumbnailUrl}
                                loading="lazy"
                                className={classes.image}
                              />
                            </Box>
                          </Box>
                        ))}
                      <PhotoDropzone
                        tertiaryCategoryId={tertiaryCategory.id}
                        onDrop={handlePhotoUpload}
                      />
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};
