import { theme } from "extensions/theme";

export const styles = {
  block: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.customPrimary[50],
    borderRadius: "5px",
    marginTop: "1rem",
    padding: "1rem",
  },
  button: {
    display: "flex",
    gap: "8px",
    width: "100%",
    height: "32px",
    backgroundColor: theme.palette.grayScale[0],
  },
};
