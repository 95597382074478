import React from "react";
import { OpenInNew } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { BillingTableColumnLabel } from "components/label/billing-table-column-label";
import { ProjectBilling } from "data-access/repositories/project/project_billing/project_billing.dto";
import { theme } from "extensions/theme";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { openURLInNewTab } from "utils/openURLInNewTab";

interface Props {
  billing: ProjectBilling;
}

export const BillingCard = (props: Props) => {
  const mainState = useAppSelector(selectMain);

  const handleClick = () => {
    openURLInNewTab(`billings/${props.billing.billing.id}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.customPrimary[50],
        border: `1px solid ${theme.palette.grayScale[300]}`,
        borderRadius: "5px",
        p: "12px",
        width: "100%",
        mb: "16px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: "bold", mb: "6px" }}>
          {props.billing.billing.code} : {props.billing.billing.subject}
        </Typography>
        <IconButton onClick={handleClick} sx={{ p: 0 }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              color: theme.palette.primary.main,
              mr: "4px",
            }}
          >
            請求詳細
          </Typography>
          <OpenInNew fontSize="small" color="primary" />
        </IconButton>
      </Box>
      <Divider sx={{ my: "12px" }} />
      <div>
        <div style={{ display: "flex", marginBottom: "12px", gap: "24px" }}>
          <div>
            <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
              請求金額
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              ¥{props.billing.amount.toLocaleString()}
            </Typography>
            <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>
              税込¥{(props.billing.amount + props.billing.tax).toLocaleString()}
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
              請求日
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              {props.billing.billing.billing_date?.toString()}
            </Typography>
          </div>
          {mainState.company.company_setting.is_billing_status_type_use && (
            <div>
              <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "4px" }}>
                送付ステータス
              </Typography>
              <BillingTableColumnLabel type={props.billing.billing.status_type} />
            </div>
          )}
          <div>
            <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "4px" }}>
              入金ステータス
            </Typography>
            <BillingTableColumnLabel
              type={props.billing.billing.deposit_status_type}
              sx={{ width: "100%", fontSize: "11px" }}
            />
          </div>
        </div>

        <div>
          <Typography sx={{ fontSize: "12px", color: theme.palette.grayScale[700], mb: "8px" }}>
            請求メモ
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
            {props.billing.billing.note.split("\n").map((str: string, index: number) => {
              return (
                <React.Fragment key={index}>
                  {str}
                  <br />
                </React.Fragment>
              );
            })}
          </Typography>
        </div>
      </div>
    </Box>
  );
};
