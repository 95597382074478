// ファイルアップロード中に操作をブロックするカスタムフック
export const useBlockOperationsWhileUploading = () => {
  const browserCallBack = () => {
    const confirmResult = confirm(
      "このページを離れると、ファイルのアップロードが中断されますがよろしいですか？",
    );
    // 「OK」→ブラウザバックされる、「キャンセル」→画面は切り替えない
    if (confirmResult) {
      history.go(-1);
    } else {
      history.go(1);
    }
  };
  const reloadCallBack = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const blockOperationsWhileUpload = () => {
    // ブラウザバックで発火
    window.addEventListener("popstate", browserCallBack);
    // リロードorタブ閉じるときに発火
    window.addEventListener("beforeunload", reloadCallBack);
  };
  const releaseBlockOperationsWhileUpload = () => {
    window.removeEventListener("popstate", browserCallBack);
    window.removeEventListener("beforeunload", reloadCallBack);
  };

  return {
    blockOperationsWhileUpload,
    releaseBlockOperationsWhileUpload,
  };
};
