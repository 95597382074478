export const styles = {
  checkbox: {
    justifyContent: "center",
    "&.MuiFormControlLabel-root": {
      margin: 0,
    },
  },
  selectBox: {
    width: "auto",
    height: "40px",
    "&.MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  contentField: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
};
