import React, { ChangeEvent } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Select, TablePagination, MenuItem, SelectChangeEvent, Button } from "@mui/material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {
  DataGridPro,
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
  gridSortModelSelector,
  GridSortModel,
  GridColDef,
  GridCellModesModel,
  GridCellParams,
  GridCellModes,
  GridEditMode,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { PageInfo } from "data-access/repositories/page_info/page_info.dto";
import { theme } from "extensions/theme";
import { styles } from "./styles";

type Props = {
  type: "project" | "billing" | "workReport" | "scheduleSearchResult";
  editMode?: GridEditMode;
  columns: GridColDef[];
  rows: any;
  loading: boolean;
  pageInfo: PageInfo;
  currentPage: number;
  tableHeight: string;
  handleDownloadButton?: () => void;
  handleSelectPage: (event: SelectChangeEvent<unknown>) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleRowClick?: (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails,
  ) => void;
  /** ワンクリックで編集したい項目があった際に */
  handleCellEdited?: (id: number, updatedRowParams: { [key: string]: any }) => void;
  handleCellClick?: (params: GridCellParams, event: React.MouseEvent) => void;
  handleCheck?: any;
  initialState?: GridInitialStatePro;
  isCheckbox?: boolean;
  isCheckAllNoneStyle?: boolean;
  handleColumnOrderChange?: (allColumns: GridColDef[]) => void;
  handleColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void;
  handleSortModelChange?: (sortItems: GridSortModel) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  rowSelectableFunc?: (params: GridRowParams) => boolean;
  rowSelection?: any;
};

export const CustomDataGridPro = ({
  editMode = "row",
  isCheckAllNoneStyle = false,
  ...props
}: Props) => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const rowSelectionModel = props.rowSelection ? props.rowSelection : undefined;

  // 編集モードなどに切り替えるためのstate
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const onCellClick = React.useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (props.handleCellClick) {
      props.handleCellClick(params, event);
    }
    // 編集可能なセルの場合、onRowClickへの伝播を防ぐ(画面遷移など）
    if (params.isEditable) {
      event.stopPropagation();
    } else {
      // 編集不可能ならセルタップとして扱わない
      return;
    }

    // Ignore portal
    if (
      (event.target as any).nodeType === 1 &&
      !event.currentTarget.contains(event.target as Element)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleColumnOrderChange = () => {
    if (typeof props.handleColumnOrderChange === "undefined") return;
    props.handleColumnOrderChange(apiRef.current.getAllColumns());
  };

  const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    if (typeof props.handleColumnVisibilityModelChange === "undefined") return;
    props.handleColumnVisibilityModelChange(model);
  };

  const handleSortModelChange = () => {
    if (typeof props.handleSortModelChange === "undefined") return;
    props.handleSortModelChange(gridSortModelSelector(apiRef));
  };

  const handleProcessRowUpdate = (newRow: any, oldRow: any) => {
    const newValues: { [key: string]: any } = {};
    Object.keys(newRow).forEach((key: string) => {
      if (newRow[key] !== oldRow[key]) {
        newValues[key] = newRow[key];
      }
    });
    props.handleCellEdited && props.handleCellEdited(newRow.id, newValues);
    // returnしないと編集状態が変わらないので注意
    return newRow;
  };

  const footer = () => {
    return (
      <CustomDataGridProFooter
        type={props.type}
        pageInfo={props.pageInfo}
        currentPage={props.currentPage}
        handleChangePage={props.handleChangePage}
        handleDownloadButton={props.handleDownloadButton}
        handleSelectPage={props.handleSelectPage}
        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
      />
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        ...(props.tableHeight ? { height: props.tableHeight } : {}),
      }}
    >
      <DataGridPro
        sx={{ display: "grid", gridTemplateRows: "auto 1f auto" }}
        loading={props.loading}
        slots={{ footer }}
        rows={props.rows}
        columns={props.columns}
        initialState={props.initialState}
        checkboxSelection={props.isCheckbox}
        disableRowSelectionOnClick
        disableColumnFilter
        onRowSelectionModelChange={(ids): void => {
          const rows = props.rows;
          const selectedIds = new Set(ids);
          const selectedRowData = rows.filter((row: { id: GridRowId }) => selectedIds.has(row.id));
          if (props.handleCheck) {
            props.handleCheck(selectedRowData);
          }
        }}
        className={`${isCheckAllNoneStyle && classes.checkAllNone} ${classes.table}`}
        rowSelectionModel={rowSelectionModel}
        isRowSelectable={props.rowSelectableFunc}
        onColumnOrderChange={() => {
          handleColumnOrderChange();
        }}
        onColumnVisibilityModelChange={(model) => {
          handleColumnVisibilityModelChange(model);
        }}
        columnVisibilityModel={props.columnVisibilityModel}
        onSortModelChange={() => {
          handleSortModelChange();
        }}
        apiRef={apiRef}
        editMode={editMode}
        onRowClick={props.handleRowClick}
        onCellClick={onCellClick}
        processRowUpdate={handleProcessRowUpdate}
        cellModesModel={cellModesModel}
        onCellModesModelChange={setCellModesModel}
      />
    </Box>
  );
};

interface CustomDataGridProFooterProps {
  type: "project" | "billing" | "workReport" | "scheduleSearchResult";
  pageInfo: PageInfo;
  currentPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleDownloadButton?: () => void;
  handleSelectPage: (event: SelectChangeEvent<unknown>) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const CustomDataGridProFooter = (props: CustomDataGridProFooterProps) => {
  const pageItemOptions = Array.from(Array(props.pageInfo.pages).keys(), (x) => x + 1).map(
    (pageItem) => {
      return (
        <MenuItem key={pageItem} value={pageItem}>
          {pageItem}
        </MenuItem>
      );
    },
  );

  // ページ変更後は、スクロール位置をトップに戻す
  const scrollToTop = () => {
    const dataGridScroller = document.querySelector(".MuiDataGrid-virtualScroller");
    if (dataGridScroller) dataGridScroller.scrollTop = 0;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        {props.type === "billing" ||
        props.type === "project" ||
        props.type == "scheduleSearchResult" ? (
          <Button
            onClick={props.handleDownloadButton}
            sx={{
              border:
                props.pageInfo.count === 0
                  ? `2px solid ${theme.palette.grayScale[300]}`
                  : `1px solid ${theme.palette.primary.main}`,
              minWidth: "162px",
              color: theme.palette.primary.main,
              borderRadius: "36px",
              height: "28px",
              fontSize: "11px",
              fontWeight: "bold",
              ml: "16px",
              mb: "12px",
            }}
            disabled={props.pageInfo.count === 0}
          >
            <FileDownloadOutlinedIcon style={{ width: "16px", height: "16px" }} />
            {props.pageInfo.count}件をCSVでダウンロード
          </Button>
        ) : (
          <div />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Select
            value={props.currentPage}
            onChange={(event) => {
              props.handleSelectPage(event);
              scrollToTop();
            }}
            variant="standard"
          >
            {pageItemOptions}
          </Select>
          <Box marginX={1}>ページ</Box>
          <TablePagination
            component="div"
            count={props.pageInfo.count}
            page={props.currentPage - 1}
            rowsPerPage={props.pageInfo.items}
            rowsPerPageOptions={[50, 100]}
            onPageChange={(event, newPage) => {
              props.handleChangePage(event, newPage);
              scrollToTop();
            }}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
            labelRowsPerPage="表示件数："
          />
        </div>
      </div>
    </>
  );
};
