export const convertTodoTagToJapanese = (englishType: string): string => {
  switch (englishType) {
    case "schedule_adjustment":
      return "日程調整";
    case "estimate_submission":
      return "見積提出";
    case "material_ordering":
      return "部材発注";
    case "partner_order":
      return "協力会社発注";
    case "drawing_creation":
      return "図面作成";
    case "site_preview":
      return "現場調査";
    default:
      return "その他";
  }
};
