import { useState } from "react";
import { addressRepository } from "data-access/repositories/address/address.repository";

export const useGetAddress = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getAddressByPostalCode = async (postalCode: string) => {
    try {
      const res = await addressRepository.index(postalCode.replace(/-/g, ""));
      const address = res.data[0].prefecture + res.data[0].city + res.data[0].town;
      setErrorMessage("");
      return address;
    } catch {
      setErrorMessage("住所が見つかりませんでした");
      return "";
    }
  };

  return {
    addressErrorMessage: errorMessage,
    getAddressByPostalCode,
  };
};
