import { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { theme } from "extensions/theme";

interface Props {
  name: string;
  width: string;
  height?: string;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  blurFunc?: (e: React.FocusEvent<HTMLInputElement>, value: number) => void;
  step?: number;
  isBgWhite?: boolean;
}

export const AmountTextField = ({
  name,
  width,
  height,
  value,
  onChange,
  blurFunc,
  step = 1000,
  isBgWhite = false,
}: Props) => {
  const [amount, setAmount] = useState<number>(value);
  const [isDisplayEditField, setIsDisplayEditField] = useState<boolean>(false);

  useEffect(() => {
    setAmount(value);
  }, [value]);

  const convertFullWidthNumToHalf = (str: string) => {
    return str.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAmount(Number(e.target.value));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsDisplayEditField(false);
    const value = e.target.value;
    const convertedValue = convertFullWidthNumToHalf(value);
    const commaValue = convertedValue.replace(/,/g, "");
    const numValue = Number(commaValue);
    if (isNaN(numValue)) {
      setAmount(0);
    } else {
      setAmount(numValue);
    }
    if (blurFunc) {
      blurFunc(e, numValue);
    }
  };

  const handleFocus = () => {
    setIsDisplayEditField(true);
    // 表示用のTexFieldをクリックしたとき編集用のTextFieldにフォーカスを当てる
    setTimeout(() => {
      const element = document.getElementById(name);
      element?.focus();
    }, 0);
  };

  return (
    <>
      {/* 表示用のTextField */}
      {!isDisplayEditField && (
        <TextField
          value={Number(amount).toLocaleString()}
          onFocus={handleFocus}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">¥</InputAdornment>,
            },
          }}
          sx={{
            bgcolor: isBgWhite ? theme.palette.grayScale[0] : "",
            width: width,
            "& .MuiOutlinedInput-root": {
              height: height ? height : "40px",
            },
          }}
        />
      )}
      {/* 編集用のTextField */}
      {isDisplayEditField && (
        <TextField
          id={name}
          name={name}
          type="number"
          value={amount === 0 ? "" : amount}
          onChange={(e) => {
            onChange(e);
            handleChange(e);
          }}
          onBlur={handleBlur}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">¥</InputAdornment>,
            },
            htmlInput: {
              step,
            },
          }}
          sx={{
            bgcolor: isBgWhite ? theme.palette.grayScale[0] : "",
            width: width,
            "& .MuiOutlinedInput-root": {
              height: height ? height : "40px",
            },
          }}
        />
      )}
    </>
  );
};
