import { ElementRef, useMemo, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "components/atoms/primary-button";
import { ExpandToggleLabel } from "components/label/expand-toggle-label";
import { WorkReportBlock } from "components/molecules/work-report-block";
import { WorkReportDetailModal } from "components/organisms/work-report/detail-modal";
import { AsyncConfirmDialog } from "components/templates/async-confirm-dialog";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { projectWorkReportRepository } from "data-access/repositories/project/work_report/work_report.repository";
import {
  WorkReport,
  WorkReportId,
  WorkReportState,
} from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR, { useSWRConfig } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { ProjectSidebarWorkReportInitialDisplayNumber } from "utils/constant";
import { handleReduxError } from "utils/errorHandler";
import { WorkReportCreateModal } from "../../../../../components/organisms/work-report/create-modal";

interface Props {
  isCreateNew: boolean;
  projectId?: ProjectId;
}

export const ProjectSidebarWorkReportContent = (props: Props) => {
  const dispatch = useAppDispatch();

  const fetchIndexKey = props.projectId ? API_PATHS.getProjectWorkReports(props.projectId) : "";
  const { data, mutate } = useSWR(fetchIndexKey, () => {
    return projectWorkReportRepository.index(props.projectId as ProjectId);
  });
  const { mutate: IndexMutate } = useSWRConfig();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [show, setShow] = useState<{ isOpen: boolean; id: WorkReportId | undefined }>({
    isOpen: false,
    id: undefined,
  });

  const deleteConfirmRef = useRef<ElementRef<typeof AsyncConfirmDialog>>(null);
  const handleDelete = async (id: WorkReportId) => {
    if (!deleteConfirmRef.current) return;
    const res = await deleteConfirmRef.current.confirm();

    if (res) {
      try {
        await workReportRepository.destroy(id as WorkReportId);
        mutate();
        setShow({ isOpen: false, id: undefined });
        dispatch(mainOperations.updateSuccessMessage("作業日報を削除しました"));
      } catch (error) {
        dispatch(mainOperations.updateErrorMessage(error.response.data.message));
      }
    }
  };

  const displayNumber = useMemo(() => {
    if (expanded) {
      return data?.length || 0;
    }
    return ProjectSidebarWorkReportInitialDisplayNumber;
  }, [expanded]);

  const handleCreate = async (body: WorkReportState): Promise<WorkReport> => {
    try {
      const res: WorkReport = await workReportRepository.create({
        projectId: props.projectId,
        ...body,
      });
      IndexMutate(fetchIndexKey);
      dispatch(mainOperations.updateSuccessMessage("作業日報を作成しました"));
      return res;
    } catch (error) {
      handleReduxError(error, dispatch, "作業日報の作成に失敗しました");
      throw error;
    }
  };

  return (
    <>
      <AsyncConfirmDialog ref={deleteConfirmRef} />
      <WorkReportDetailModal
        show={show}
        setShow={setShow}
        onDelete={handleDelete}
        fetchIndexKey={fetchIndexKey}
      />
      {props.projectId && (
        <WorkReportCreateModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleSubmit={handleCreate}
          projectId={props.projectId}
          fetchIndexKeys={[fetchIndexKey]}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: "8px" }}>
        <Typography
          sx={{
            color: theme.palette.grayScale[700],
            fontSize: "16px",
            height: "34px",
            lineHeight: "34px",
          }}
        >
          作業日報
        </Typography>
        <PrimaryButton
          name="作業日報を作成"
          onClick={() => setIsOpen(true)}
          disabled={props.isCreateNew}
        />
      </Box>
      {data?.length === 0 && <Typography>作業日報はありません</Typography>}
      {data
        ?.slice(0, displayNumber)
        .map((work_report) => (
          <WorkReportBlock key={work_report.id} workReport={work_report} setShow={setShow} />
        ))}
      {(data?.length || 0) > ProjectSidebarWorkReportInitialDisplayNumber && (
        <ExpandToggleLabel onClick={() => setExpanded(!expanded)} expanded={expanded} />
      )}
    </>
  );
};
