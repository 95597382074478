import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { CustomModal } from "components/molecules/custom-modal";
import { Client } from "data-access/repositories/client/client.dto";
import { ProjectFormUpdateRequest } from "data-access/repositories/project/project.dto";
import { useAppSelector } from "store/hooks";
import { selectMain } from "store/main/slice";
import { formatDateUtil } from "utils/formatDateUtil";
import { styles } from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedClient: Client;
  state: ProjectFormUpdateRequest;
}
export const ReportContentPrintModal = (props: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  return (
    <CustomModal
      open={props.isOpen}
      onClose={props.onClose}
      title="報告内容を印刷・PDFで保存"
      footer={
        <React.Fragment>
          <Button variant="outlined" onClick={props.onClose} sx={{ px: "3rem" }}>
            キャンセル
          </Button>
          <Button
            onClick={() => reactToPrintFn()}
            variant="contained"
            sx={{ px: "5rem", ml: "1rem" }}
          >
            印刷
          </Button>
        </React.Fragment>
      }
    >
      <Box sx={{ ...styles.reportContent }}>
        <ReportContent isColorOn={true} client={props.selectedClient} projectState={props.state} />
      </Box>

      <div style={{ display: "none" }}>
        <Box ref={contentRef} sx={{ ...styles.reportContent }}>
          <ReportContent
            isColorOn={false}
            client={props.selectedClient}
            projectState={props.state}
          />
        </Box>
      </div>
    </CustomModal>
  );
};
interface ReportContentProps {
  isColorOn: boolean;
  client: Client;
  projectState: ProjectFormUpdateRequest;
}
const ReportContent = (props: ReportContentProps) => {
  const mainState = useAppSelector(selectMain);

  const [state, setState] = useState<{
    requestedByName: string;
    activityType: "受注" | "訪問日時" | "訪問" | "作業";
    behaviorType: "決定" | "完了";
    reportedByName: string;
  }>({
    requestedByName: "",
    activityType: "受注",
    behaviorType: "決定",
    reportedByName: "",
  });

  useEffect(() => {
    // 初期値の設定
    setState((prev) => ({
      ...prev,
      requestedByName: `${props.projectState.requestedByPersonName ? props.projectState.requestedByPersonName : ""} 様`,
      reportedByName: inquiredByName,
    }));
  }, []);

  const projectTypeName = mainState.projectTypes.find(
    (projectType) => projectType.id === props.projectState.projectTypeId,
  )?.name;
  const inquiredByName =
    mainState.users.find((user) => user.id === props.projectState.inquiredById)?.name || "";

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
  ) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography>
            {props.client?.name}{" "}
            {state.requestedByName === "" &&
              (props.client?.honorific_title === "nothing"
                ? ""
                : props.client?.honorific_title_i18n)}
          </Typography>
          <TextField
            id="requestedByName"
            name="requestedByName"
            sx={{ ...(props.isColorOn ? styles.canInput : {}), mt: 1 }}
            value={state.requestedByName}
            slotProps={{
              htmlInput: {
                style: {
                  padding: 0,
                },
              },
            }}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <Typography textAlign="right">{mainState.company.name}</Typography>
          <Typography textAlign="right">{mainState.company.address}</Typography>
          <Typography textAlign="right">TEL: {mainState.company.phone_number}</Typography>
          <Typography textAlign="right">FAX: {mainState.company.fax_number}</Typography>
        </Box>
      </Box>
      <Typography sx={{ mt: "1rem", fontSize: "1.3rem", textAlign: "center" }}>
        {projectTypeName}受注物件報告書
      </Typography>
      <Typography sx={{ mt: "0.5rem", textAlign: "center" }}>
        ご依頼を頂き誠にありがとうございました。
        <br />
        下記の通り
        <Select
          id="activityType"
          name="activityType"
          variant="standard"
          sx={{ ...(props.isColorOn ? styles.canInput : ""), ml: "0.5rem" }}
          onChange={handleChange}
          value={state.activityType}
          disableUnderline
        >
          <MenuItem value="受注">受注</MenuItem>
          <MenuItem value="訪問日時">訪問日時</MenuItem>
          <MenuItem value="訪問">訪問</MenuItem>
          <MenuItem value="作業">作業</MenuItem>
        </Select>
        を
        <Select
          id="behaviorType"
          name="behaviorType"
          variant="standard"
          sx={{ ...(props.isColorOn ? styles.canInput : ""), ml: "0.5rem" }}
          value={state.behaviorType}
          disableUnderline
          onChange={handleChange}
        >
          <MenuItem value="決定">決定</MenuItem>
          <MenuItem value="完了">完了</MenuItem>
        </Select>
        いたしましたのでご報告させていただきます。
      </Typography>

      <Table sx={{ mt: "1rem" }}>
        <TableBody>
          <TableRow>
            <TableCell component="th">現場名</TableCell>
            <TableCell>
              {props.projectState.code}：{props.projectState.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">現場住所</TableCell>
            <TableCell>
              {props.projectState.address} {props.projectState.addressSecond}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table sx={{ mt: "1rem" }}>
        <TableBody>
          <TableRow>
            <TableCell component="th">FAX又はTEL ご依頼日</TableCell>
            <TableCell>
              {props.projectState.inquiredDate &&
                formatDateUtil(new Date(props.projectState.inquiredDate), "yyyy/MM/dd")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">ご依頼内容</TableCell>
            <TableCell>
              {props.projectState.note &&
                props.projectState.note.split("\n").map((str: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {str}
                      <br />
                    </React.Fragment>
                  );
                })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">受付担当者</TableCell>
            <TableCell>{projectTypeName}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table sx={{ mt: "1rem" }}>
        <TableBody>
          {projectTypeName !== "物販" && (
            <TableRow>
              <TableCell component="th">{projectTypeName}訪問日</TableCell>
              <TableCell>{props.projectState.expectedCompleteDate}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th">報告内容</TableCell>
            <TableCell>
              {props.projectState.reportContent &&
                props.projectState.reportContent.split("\n").map((str: string, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {str}
                      <br />
                    </React.Fragment>
                  );
                })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table sx={{ mt: "1rem" }}>
        <TableBody>
          <TableRow>
            <TableCell component="th">{projectTypeName}完了日</TableCell>
            <TableCell>{props.projectState.completedDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">報告担当者</TableCell>
            <TableCell sx={{ p: 0, fontSize: "1.1rem" }}>
              <TextField
                id="reportedByName"
                name="reportedByName"
                sx={{ ...(props.isColorOn ? styles.canInput : ""), m: 0, p: 0 }}
                fullWidth
                value={state.reportedByName}
                onChange={handleChange}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
