import { useState } from "react";
import { ActivityBlock } from "components/InfoBlock/activity";
import { projectActivityRepository } from "data-access/repositories/project/activity/project_activity.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { ACTIVITY_PER_PAGE } from "utils/constant";

interface Props {
  projectId: ProjectId;
}

export const ProjectSidebarActivityContent = (props: Props) => {
  const [limit, setLimit] = useState<number>(ACTIVITY_PER_PAGE);
  const { data: activities } = useSWR(
    props.projectId ? API_PATHS.getProjectActivities(props.projectId, limit) : null,
    () => projectActivityRepository.index(props.projectId, limit),
    {
      revalidateOnFocus: false,
    },
  );

  return (
    <ActivityBlock
      activities={activities?.data || []}
      totalCount={activities?.pagination.count || 0}
      limit={limit}
      setLimit={setLimit}
    />
  );
};
