import { configureStore } from "@reduxjs/toolkit";
import billingSidebarReducer from "./billing-sidebar/slice";
import billingTableReducer from "./billing-table/slice";
import billingTableHeaderReducer from "./billing-table-header/slice";
import calendarReducer from "./calendar/slice";
import deleteConfirmDialogReducer from "./delete-confirm-dialog/slice";
import discardChangesConfirmDialogReducer from "./discard-changes-confirm-dialog/slice";
import mainReducer from "./main/slice";
import scheduleDeleteConfirmDialogReducer from "./schedule-delete-confirm-dialog/slice";
import scheduleRightClickMenuReducer from "./schedule-right-click-menu/slice";

export const store = configureStore({
  reducer: {
    main: mainReducer,
    calendar: calendarReducer,
    billingTable: billingTableReducer,
    billingTableHeader: billingTableHeaderReducer,
    billingSidebar: billingSidebarReducer,
    deleteConfirmDialog: deleteConfirmDialogReducer,
    scheduleDeleteConfirmDialog: scheduleDeleteConfirmDialogReducer,
    scheduleRightClickMenu: scheduleRightClickMenuReducer,
    discardChangesConfirmDialog: discardChangesConfirmDialogReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
