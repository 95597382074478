import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LockIcon from "@mui/icons-material/LockOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  SelectChangeEvent,
  FormControlLabel,
  Autocomplete,
  Typography,
  AutocompleteInputChangeReason,
  CircularProgress,
  AutocompleteChangeReason,
  Divider,
  InputLabel,
  FormControl,
  Backdrop,
  Box,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AmountTextField } from "components/atoms/amount-text-field";
import { CustomAutocomplete } from "components/atoms/custom-autocomplete";
import { CustomDatePicker } from "components/atoms/custom-date-picker";
import { Flash } from "components/atoms/flash";
import { TagLabel } from "components/label/tag-label";
import { ConfirmDialog } from "components/molecules/confirm-dialog";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { CustomModal } from "components/molecules/custom-modal";
import { RecurrenceRuleSettingModal } from "components/molecules/recurrence-rule-setting-modal";
import { SelectedMembers } from "components/molecules/selected-members";
import { addressRepository } from "data-access/repositories/address/address.repository";
import { Building, BuildingId } from "data-access/repositories/building/building.dto";
import { buildingRepository } from "data-access/repositories/building/building.repository";
import { ClientId, Client } from "data-access/repositories/client/client.dto";
import { clientRepository } from "data-access/repositories/client/client.repository";
import { postalCodeRepository } from "data-access/repositories/postal_code/postal_code.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import {
  KeyName,
  RecurrenceRule,
  RecurrenceRuleParams,
} from "data-access/repositories/recurrence_rule/recurrence_rule.dto";
import { RecurrenceRuleOptionsRepository } from "data-access/repositories/recurrence_rule_options/recurrence_rule_options.repository";
import {
  ScheduleCreateRequest,
  initialRecurrenceRule,
  initialScheduleCreateRequest,
} from "data-access/repositories/schedule/schedule.dto";
import { scheduleRepository } from "data-access/repositories/schedule/schedule.repository";
import { scheduleTypeRepository } from "data-access/repositories/schedule_type/schedule_type.repository";
import { UserId } from "data-access/repositories/user/user.dto";
import { theme } from "extensions/theme";
import { useScheduleMembers } from "hooks/schedule/useScheduleMembers";
import { useScrollToPosition } from "hooks/useScrollToPosition";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { calculatePeriodFromDate, calculatePeriodFromTime } from "utils/calculatePeriod";
import { handleStateError } from "utils/errorHandler";
import { DATE_TIME_SLASH_FORMAT } from "utils/formatDateUtil";
import { openURLInNewTab } from "utils/openURLInNewTab";

const STypography = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;
const STextField = styled(TextField)`
  width: 330px;
  & .MuiOutlinedInput-root {
    height: 46px;
  }
`;

interface Props {
  obj: {
    isOpen: boolean;
    startTime: string;
    endTime: string;
    userIds?: UserId[];
  };
  func: () => void;
  fetchIndexKey: string;
  fetchNoDatedEventKey: string;
}

export const ScheduleCreateModal = (props: Props) => {
  const { obj, func: resetModalState, fetchIndexKey, fetchNoDatedEventKey } = props;
  const { isOpen, startTime: initStartDateTime, endTime: initEndDateTime, userIds } = obj;

  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const { scrollToSavedPosition } = useScrollToPosition("calendar");

  const [isChange, setIsChange] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [selectedRecurrenceRule, setSelectedRecurrenceRule] = useState<{
    keyName: KeyName;
    label: string;
  }>({ keyName: "none", label: "" });
  const [recurrenceSettingModalState, setRecurrenceSettingModalState] = useState<{
    isOpen: boolean;
    recurrenceRule?: RecurrenceRule;
  }>({
    isOpen: false,
  });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string>("");
  const [addressErrorMessage, setAddressErrorMessage] = useState<string>("");
  const [searchedAddress, setSearchedAddress] = useState<string>("");
  const [isCreateNewProject, setIsCreateNewProject] = useState<boolean>(false);
  const [isCreateNewClient, setIsCreateNewClient] = useState<boolean>(false);
  // 案件のcomboBoxに表示する案件名
  const [projectName, setProjectName] = useState<string>("");
  // 顧客のcomboBoxに表示する顧客名
  const [clientName, setClientName] = useState<string>("");
  // 案件のcomboBoxで検索時に入力された値
  const [inputProjectValue, setInputProjectValue] = useState<string>("");
  const [formState, setFormState] = useState<ScheduleCreateRequest>(initialScheduleCreateRequest);

  const {
    data: recurrenceRuleOptions,
    mutate: recurrenceRuleOptionsMutate,
    isValidating: isLoadingRecurrenceRule,
  } = useSWR(
    isOpen && formState.startTime ? "/api/v1/recurrence_rule_options" : null,
    () =>
      RecurrenceRuleOptionsRepository.index({
        scheduleStartTime: formState.startTime ? formState.startTime : new Date().toString(),
      }),
    { revalidateOnFocus: false },
  );

  const { data: scheduleTypes, isLoading: isLoadingScheduleTypes } = useSWR(
    isOpen ? "/api/v1/schedule_types" : null,
    scheduleTypeRepository.index,
    { revalidateOnFocus: false },
  );
  const { data: rawProjectsData, isValidating } = useSWR(
    isOpen ? `/api/v1/projects?query=${inputProjectValue}` : null,
    () =>
      projectRepository.index({
        keyword: inputProjectValue,
        sort: "updated_at",
        direction: "desc",
      }),
    {
      revalidateOnFocus: false,
    },
  );
  const { data: clients } = useSWR(isOpen ? "/api/v1/clients/all" : null, clientRepository.all, {
    revalidateOnFocus: false,
  });

  const { data: rawBuildingsData } = useSWR(isOpen ? "/api/v1/buildings/all" : null, () =>
    buildingRepository.all({ orderBy: "recently_created" }),
  );

  const projects = rawProjectsData
    ? rawProjectsData.data.map((project) => {
        return {
          id: project.id,
          name: project.name,
          code: project.code,
        };
      })
    : [];

  const buildings = rawBuildingsData
    ? rawBuildingsData.map((building) => {
        return {
          id: building.id,
          name: building.name,
          createOption: false,
        };
      })
    : [];

  useEffect(() => {
    if (!isOpen || !scheduleTypes) return;

    setFormState({
      ...formState,
      startTime: initStartDateTime,
      endTime: initEndDateTime,
      scheduleTypeId: scheduleTypes[0].id,
      note: mainState.company.company_setting.schedule_note_template,
      userIds: userIds || [],
    });
    setSelectedRecurrenceRule({ keyName: "none", label: "" });
  }, [scheduleTypes, obj]);

  // 開始日時を変更したら繰り返しプルダウンリストを更新する
  useEffect(() => {
    recurrenceRuleOptionsMutate();
  }, [formState.startTime]);

  // 繰り返し設定モーダルで設定した値を反映
  useEffect(() => {
    if (!recurrenceSettingModalState.recurrenceRule) return;
    setFormState({
      ...formState,
      recurrenceRule: {
        ...recurrenceSettingModalState.recurrenceRule.params,
        endDate: recurrenceSettingModalState.recurrenceRule.params.endDate || null,
      },
    });
    setSelectedRecurrenceRule({
      keyName: recurrenceSettingModalState.recurrenceRule.keyName,
      label: recurrenceSettingModalState.recurrenceRule.label,
    });
  }, [recurrenceSettingModalState.recurrenceRule]);

  const createSchedule = async () => {
    setIsSubmit(true);
    try {
      await scheduleRepository.create(formState);
      handleClose();
      dispatch(mainOperations.updateSuccessMessage("予定を作成しました"));
      mutate(fetchIndexKey);
      setErrorMessage("");
    } catch (error) {
      handleStateError(error, setErrorMessage, "予定の作成に失敗しました");
    } finally {
      setIsSubmit(false);
      scrollToSavedPosition();
      mutate(fetchNoDatedEventKey);
    }
  };

  const {
    selectedCompanyUsers,
    inputValue,
    allOptions,
    selectableSiteManagers,
    isLoading: isLoadingMembers,
    resetMembers,
    handleChangeUsers,
    handleClear,
    setInputValue,
    filterOptions,
    groupByFunction,
    isOverlapConfirmModalOpen,
    handleCreateWithOverlapCheck,
    handleCancelOverlapConfirm,
  } = useScheduleMembers({
    isModalOpen: props.obj.isOpen,
    startTime: formState.startTime,
    endTime: formState.endTime,
    initialUserIds: userIds,
    onMembersChange: (newUserIds) => {
      setFormState({ ...formState, userIds: newUserIds });
      setIsChange(true);
    },
    onCreateSchedule: createSchedule,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | null>,
  ) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleChangeDateTime = (
    date: Date,
    e: React.SyntheticEvent<any> | undefined,
    name: string,
  ) => {
    if (date === null) return;
    setIsChange(true);
    switch (name) {
      case "startTime": {
        // 日付を変更したときはeが存在する
        const { startDateTime, endDateTime } = (
          e ? calculatePeriodFromDate : calculatePeriodFromTime
        )(
          date,
          formState.startTime ? new Date(formState.startTime) : null,
          formState.endTime ? new Date(formState.endTime) : null,
        );

        setFormState({
          ...formState,
          startTime: startDateTime?.toString() || "",
          endTime: endDateTime.toString(),
        });
        break;
      }
      case "endTime":
        return setFormState({ ...formState, endTime: date.toString() });
    }
  };

  const handleChangeRadioButton = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChange(true);
    setFormState({ ...formState, [e.target.name]: e.target.checked });
  };

  const handleSelectProject = (e: React.SyntheticEvent, value: any) => {
    setIsChange(true);
    const projectId = value ? value.id : 0;
    const projectName = value ? value.name : "";
    setProjectName(projectName);
    setFormState({ ...formState, name: formState.name || projectName, projectId });
    setInputProjectValue("");
  };

  const handleSelectClient = (e: React.SyntheticEvent, value: any) => {
    setIsChange(true);
    const clientId = value ? value.id : 0;
    const clientName = value ? value.name : "";
    setClientName(clientName);
    setFormState({ ...formState, project: { ...formState.project, clientId } });
  };

  const handleCheckCreateNewProject = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setIsCreateNewProject(!isCreateNewProject);
    setProjectName("");
    if (checked) {
      setFormState({
        ...formState,
        projectId: 0 as ProjectId,
        project: {
          ...formState.project,
          name: formState.name,
          projectTypeId: mainState.projectTypes[0].id,
        },
      });
    } else {
      setFormState({
        ...formState,
        project: undefined,
      });
    }
  };

  const handleChangeProjectSearchInputValue = (
    _: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason !== "input") return;
    setInputProjectValue(value);
  };

  const handleCheckCreateNewClient = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsCreateNewClient(!isCreateNewClient);
    setClientName("");
    if (checked) {
      setFormState({
        ...formState,
        project: {
          ...formState.project,
          clientId: 0 as ClientId,
          client: { name: formState.name },
        },
      });
    } else {
      setFormState({
        ...formState,
        project: { ...formState.project, client: undefined },
      });
    }
  };

  const handleChangeProjectForm = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>,
  ) => {
    setIsChange(true);
    const value = e.target.value;
    setFormState({ ...formState, project: { ...formState.project, [e.target.name]: value } });
  };

  const handleChangeClientForm = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsChange(true);
    const value = e.target.value;
    setFormState({ ...formState, project: { ...formState.project, client: { name: value } } });
  };

  const handleClickSearchAddress = async () => {
    const postalCode = formState.project?.postalCode;
    if (!postalCode) return;
    try {
      const res = await addressRepository.index(postalCode);
      const address = res.data[0].prefecture + res.data[0].city + res.data[0].town;
      setFormState({ ...formState, project: { ...formState.project, address } });
      setPostalCodeErrorMessage("");
    } catch {
      setPostalCodeErrorMessage("入力した郵便番号から住所が見つかりませんでした");
    }
  };

  const handleClickSearchPostalCode = async () => {
    const address = formState.project?.address;
    if (!address) return;
    try {
      const res = await postalCodeRepository.index(address);
      setFormState({
        ...formState,
        project: { ...formState.project, postalCode: res.data[0].postal_code },
      });
      setSearchedAddress(
        res.data.length ? res.data[0].prefecture + res.data[0].city + res.data[0].town : "",
      );
      setAddressErrorMessage("");
    } catch {
      setSearchedAddress("");
      setAddressErrorMessage("入力した住所から郵便番号が見つかりませんでした");
    }
  };

  const handleCreateBuilding = () => {
    openURLInNewTab("buildings/new");
  };

  const handleChangeBuilding = (
    _: React.SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason,
  ) => {
    if (!rawBuildingsData) return;
    setIsChange(true);
    if (reason === "selectOption") {
      const selectBuilding: Building[] = rawBuildingsData.filter((building) => {
        return building.id === value.id;
      });
      setFormState({
        ...formState,
        name: formState.name || selectBuilding[0].name,
        project: {
          ...formState.project,
          name: selectBuilding[0].name,
          postalCode: selectBuilding[0].postal_code,
          address: selectBuilding[0].address,
          buildingId: value.id,
          clientId: selectBuilding[0].client?.id,
        },
      });
      setClientName(selectBuilding[0].client ? selectBuilding[0].client.name : "");
    }
    if (reason === "clear") {
      setFormState({
        ...formState,
        project: { ...formState.project, buildingId: 0 as BuildingId },
      });
    }
  };

  const projectFilterOptions = (
    options: {
      id: ProjectId;
      name: string;
      code: string;
    }[],
  ) => {
    return options;
  };

  const clientFilterOptions = (options: Client[], { inputValue }: { inputValue: string }) => {
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phone_number.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.phone_number_second.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const handleSubmit = async () => {
    handleCreateWithOverlapCheck();
  };

  const handleClose = () => {
    resetModalState();
    resetMembers();
    setFormState(initialScheduleCreateRequest);
    setIsCreateNewProject(false);
    setIsCreateNewClient(false);
    setProjectName("");
    setClientName("");
    setErrorMessage("");
    setIsChange(false);
    setIsDeleteModalOpen(false);
    setSelectedRecurrenceRule({ keyName: "none", label: "" });
  };

  const isValid = (): boolean => {
    if (isCreateNewClient) {
      if (!formState.project?.client?.name) return false;
    }
    return true;
  };

  const handleChangeRecurrence = (e: SelectChangeEvent<string>, child: React.ReactNode) => {
    setIsChange(true);
    const { value } = e.target;
    if (value === "custom") {
      setRecurrenceSettingModalState({
        isOpen: true,
        recurrenceRule: {
          keyName: selectedRecurrenceRule.keyName,
          label: selectedRecurrenceRule.label,
          params: formState.recurrenceRule || initialRecurrenceRule,
        },
      });
      return;
    }

    switch (value) {
      case "none":
        setFormState({ ...formState, recurrenceRule: undefined });
        break;
      default: {
        const dataParams: RecurrenceRuleParams = (child as ReactElement).props["data-params"];
        setFormState({
          ...formState,
          recurrenceRule: { ...dataParams, endDate: dataParams.endDate || "" },
        });
        break;
      }
    }
    setSelectedRecurrenceRule({
      keyName: value as KeyName,
      label: (child as ReactElement).props.children,
    });
  };

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  // エラーメッセージが表示されたとき、案件情報が展開されたとき、顧客新規作成が選択されたときに最下部へスクロールする
  useLayoutEffect(() => {
    if (errorMessage !== "" || isCreateNewClient) {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [errorMessage, isCreateNewClient]);

  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteModalOpen}
        handleYes={handleClose}
        handleNo={() => setIsDeleteModalOpen(false)}
        content="作成せず破棄しますか？"
        yesButtonText="破棄する"
        noButtonText="キャンセル"
        yesButtonColor="error"
      />

      <ConfirmDialog
        isOpen={isOverlapConfirmModalOpen}
        handleYes={async () => {
          handleCancelOverlapConfirm();
          await createSchedule();
        }}
        handleNo={handleCancelOverlapConfirm}
        content={`他の予定と時間が重複しているメンバーがいますが、予定を保存しますか？`}
        yesButtonText="保存"
      />

      <RecurrenceRuleSettingModal
        isOpen={recurrenceSettingModalState.isOpen}
        recurrenceRule={formState.recurrenceRule || undefined}
        setRecurrenceSettingModalState={(isOpen, recurrenceRule) =>
          setRecurrenceSettingModalState({ isOpen, recurrenceRule })
        }
        onClose={() =>
          setRecurrenceSettingModalState({
            isOpen: false,
            recurrenceRule: recurrenceSettingModalState.recurrenceRule,
          })
        }
        startDate={formState.startTime || ""}
      />

      <CustomModal
        title="予定の新規作成"
        open={isOpen}
        onClose={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
        footer={
          <>
            <Button
              variant="outlined"
              onClick={() => (isChange ? setIsDeleteModalOpen(true) : handleClose())}
              sx={{ width: "160px" }}
            >
              キャンセル
            </Button>
            <Button
              disabled={isSubmit || !isValid()}
              onClick={handleSubmit}
              variant="contained"
              sx={{ width: "160px", ml: "16px" }}
            >
              作成
            </Button>
          </>
        }
      >
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={isLoadingScheduleTypes || isLoadingRecurrenceRule || isLoadingMembers}
          invisible
        >
          <CircularProgress />
        </Backdrop>

        <Box sx={{ display: "flex", height: "100%" }}>
          <div style={{ overflowY: "scroll", height: "70vh", width: "60%" }}>
            <div style={{ display: "flex", marginBottom: "24px", gap: "16px" }}>
              <TextField
                id="name"
                name="name"
                label="予定名"
                variant="standard"
                value={formState.name}
                onChange={handleChange}
                sx={{ width: "80%" }}
              />
              <Select
                id="scheduleTypeId"
                name="scheduleTypeId"
                variant="standard"
                value={formState.scheduleTypeId?.toString()}
                onChange={handleSelectChange}
                sx={{ minWidth: "100px" }}
              >
                {scheduleTypes?.map((scheduleType) => (
                  <MenuItem key={scheduleType.id} value={scheduleType.id}>
                    <TagLabel tagName={scheduleType.name} colorNumber={scheduleType.color_number} />
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div>
              <div
                style={{ display: "flex", alignItems: "center", marginBottom: "20px", gap: "12px" }}
              >
                <ScheduleIcon />
                <div>
                  <div style={{ display: "flex" }}>
                    <CustomDatePicker
                      id="startTime"
                      name="startTime"
                      date={formState.startTime ? new Date(formState.startTime) : null}
                      onChange={handleChangeDateTime}
                      showTimeSelect
                      timeIntervals={30}
                      dateFormat={DATE_TIME_SLASH_FORMAT}
                      popperPlacement="right-start"
                      placeholder="開始日時"
                    />
                    <p>〜</p>
                    <CustomDatePicker
                      id="endTime"
                      name="endTime"
                      date={formState.endTime ? new Date(formState.endTime) : null}
                      onChange={handleChangeDateTime}
                      showTimeSelect
                      timeIntervals={30}
                      dateFormat={DATE_TIME_SLASH_FORMAT}
                      popperPlacement="right-start"
                      placeholder="終了日時"
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
                      繰り返し・長期予定
                    </Typography>
                    <Select
                      value={selectedRecurrenceRule.keyName}
                      variant="standard"
                      onChange={handleChangeRecurrence}
                      disabled={!formState.startTime || !formState.endTime}
                      sx={{ minWidth: "70px", maxWidth: "70%" }}
                    >
                      {recurrenceRuleOptions?.map((option: RecurrenceRule, index) => (
                        <MenuItem key={index} value={option.keyName} data-params={option.params}>
                          {option.label}
                        </MenuItem>
                      ))}
                      {recurrenceSettingModalState.recurrenceRule &&
                        recurrenceSettingModalState.recurrenceRule.label !== "" && (
                          <MenuItem
                            value="customized"
                            data-params={recurrenceSettingModalState.recurrenceRule.params}
                          >
                            {recurrenceSettingModalState.recurrenceRule.label}
                          </MenuItem>
                        )}
                    </Select>
                  </div>
                </div>
              </div>
            </div>

            <Divider sx={{ my: "24px" }} />

            <div style={{ display: "flex", gap: "12px" }}>
              <Person2OutlinedIcon sx={{ mt: "20px" }} />
              <div style={{ width: "100%", overflow: "hidden" }}>
                <div style={{ marginBottom: "12px" }}>
                  <SelectedMembers
                    allOptions={allOptions}
                    selectedCompanyUsers={selectedCompanyUsers}
                    selectedSiteManagerId={formState.siteManagerId}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    onChangeUsers={handleChangeUsers}
                    handleClear={handleClear}
                    filterOptions={filterOptions}
                    groupByFunction={groupByFunction}
                    popperPlacement="right-start"
                  />
                </div>

                <FormControlLabel
                  label="予定を確定"
                  control={<Checkbox name="isConfirmed" onChange={handleChangeRadioButton} />}
                  checked={formState.isConfirmed}
                  sx={{
                    mb: "20px",
                    "& .MuiFormControlLabel-label": { fontSize: "14px", fontWeight: "bold" },
                  }}
                />

                <FormControl variant="standard" fullWidth>
                  <InputLabel id="site-manager-select-label">現場責任者を選択</InputLabel>
                  <Select
                    labelId="site-manager-select-label"
                    id="siteManagerId"
                    name="siteManagerId"
                    value={formState.siteManagerId || 0}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>未設定</MenuItem>
                    {selectableSiteManagers?.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <Divider sx={{ my: "24px" }} />

            <div style={{ display: "flex", gap: "16px" }}>
              <FormatAlignLeftIcon />
              <div style={{ width: "100%" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}
                >
                  <CustomFormLabel labelName="作業内容・予定メモ" />
                  <Button
                    variant="outlined"
                    onClick={() => setFormState({ ...formState, note: "" })}
                    sx={{ height: "32px", width: "98px", fontSize: "11px", fontWeight: "bold" }}
                  >
                    内容をクリア
                  </Button>
                </div>
                <TextField
                  id="note"
                  name="note"
                  multiline
                  rows={4}
                  value={formState.note}
                  onChange={handleChange}
                  fullWidth
                  sx={{ mb: "8px" }}
                />
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <LockIcon fontSize="small" sx={{ mr: "16px" }} />
              <FormControlLabel
                label="予定を非公開にする"
                control={<Checkbox name="isPrivate" onChange={handleChangeRadioButton} />}
                checked={formState.isPrivate}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    fontWeight: "bold",
                    mr: "0px",
                  },
                }}
              />
              <Tooltip
                title="予定を非公開にすると、予定の作成者と参加者以外が予定の詳細を見ることができなくなります。"
                placement="top"
              >
                <HelpOutlineIcon
                  sx={{ fontSize: "17px", mt: "2px", color: theme.palette.primary.main }}
                />
              </Tooltip>
            </div>
          </div>

          <Divider orientation="vertical" variant="middle" sx={{ mx: "24px" }} />

          <div style={{ overflowY: "scroll", height: "70vh", width: "50%" }}>
            <Typography color="primary" sx={{ mb: "8px", fontWeight: "500", my: "14px" }}>
              案件情報
            </Typography>
            <div style={{ backgroundColor: theme.palette.customPrimary[50], padding: "8px" }}>
              <Autocomplete
                id="project"
                options={projects}
                onChange={handleSelectProject}
                onBlur={() => setInputProjectValue("")}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="案件名を入力して検索"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isValidating ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                filterOptions={projectFilterOptions}
                onInputChange={handleChangeProjectSearchInputValue}
                inputValue={inputProjectValue || projectName}
                value={{ id: formState.projectId, name: projectName || "", code: "" }}
                loading={isValidating}
                disabled={isCreateNewProject}
                noOptionsText={
                  inputProjectValue ? "案件は見つかりませんでした" : "検索結果が表示されます"
                }
                sx={{
                  width: 330,
                  mb: "14px",
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "none",
                  },
                }}
              />
              <FormControlLabel
                sx={{ ml: 0, mb: "12px" }}
                labelPlacement="end"
                label={
                  <Typography sx={{ fontWeight: "500", fontSize: "14px", userSelect: "none" }}>
                    案件を新規作成して紐づける
                  </Typography>
                }
                control={
                  <Checkbox
                    id="createNewProjectCheckbox"
                    name="createNewProject"
                    sx={{ p: 0, pr: 1 }}
                    onChange={handleCheckCreateNewProject}
                    checked={isCreateNewProject}
                  />
                }
              />
              {isCreateNewProject && (
                <>
                  <div style={{ marginBottom: "24px" }}>
                    <CustomAutocomplete
                      data={buildings}
                      entityName="物件"
                      entityLabel="案件に紐づける物件を選択"
                      onChange={handleChangeBuilding}
                      onClick={handleCreateBuilding}
                      isCreate
                      isClearable
                      variant="standard"
                      sx={{ width: "300px" }}
                    />
                  </div>
                  <div style={{ marginBottom: "24px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      <STypography>案件タイプ</STypography>
                      <Select
                        id="projectTypeId"
                        name="projectTypeId"
                        value={
                          formState.project?.projectTypeId === 0
                            ? ""
                            : formState.project?.projectTypeId?.toString()
                        }
                        onChange={handleChangeProjectForm}
                        sx={{
                          width: "150px",
                          "&.MuiInputBase-root": {
                            height: "46px",
                          },
                        }}
                      >
                        {mainState.projectTypes.map((projectType) => (
                          <MenuItem key={projectType.id} value={projectType.id}>
                            {projectType.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      <STypography>案件名</STypography>
                      <STextField
                        id="name"
                        name="name"
                        value={formState.project?.name}
                        onChange={handleChangeProjectForm}
                      />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      <STypography>売上（税抜）</STypography>
                      <AmountTextField
                        name="salesAmount"
                        width="330px"
                        height="46px"
                        value={formState.project?.salesAmount || 0}
                        onChange={handleChangeProjectForm}
                      />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <STypography>郵便番号</STypography>
                        <Button
                          onClick={handleClickSearchAddress}
                          disabled={formState.project?.postalCode?.length !== 7}
                          sx={{ py: 0, ml: 2, mb: "8px" }}
                        >
                          住所を検索
                        </Button>
                      </div>
                      <STextField
                        id="postalCode"
                        name="postalCode"
                        value={formState.project?.postalCode}
                        error={addressErrorMessage.length > 0}
                        helperText={addressErrorMessage}
                        placeholder="半角数字7ケタ,ハイフン無し"
                        onChange={handleChangeProjectForm}
                      />
                      {searchedAddress && (
                        <Typography sx={{ position: "relative", bottom: "0.25rem" }}>
                          検索結果: {searchedAddress}
                        </Typography>
                      )}
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <STypography>住所</STypography>
                        <Button
                          onClick={handleClickSearchPostalCode}
                          disabled={
                            formState.project?.address === undefined ||
                            formState.project?.address?.length === 0
                          }
                          sx={{ py: 0, ml: 2, mb: "8px" }}
                        >
                          郵便番号を検索
                        </Button>
                      </div>
                      <STextField
                        id="address"
                        name="address"
                        value={formState.project?.address}
                        error={postalCodeErrorMessage.length > 0}
                        helperText={postalCodeErrorMessage}
                        onChange={handleChangeProjectForm}
                        placeholder="東京都渋谷区神泉町8-1"
                      />
                    </div>
                    <div>
                      <STypography>建物名・階・号室</STypography>
                      <STextField
                        id="addressSecond"
                        name="addressSecond"
                        value={formState.project?.addressSecond}
                        onChange={handleChangeProjectForm}
                        placeholder="フォーラム渋谷神泉7階"
                      />
                    </div>
                  </div>

                  <Typography color="primary">顧客情報</Typography>
                  <CustomAutocomplete
                    data={clients || []}
                    value={{ id: formState.project?.clientId || 0, name: clientName || "" }}
                    onChange={handleSelectClient}
                    entityName="顧客"
                    entityLabel="顧客"
                    variant="standard"
                    disabled={isCreateNewClient}
                    isClearable
                    filterOptions={clientFilterOptions}
                    sx={{ width: 330, mb: "14px" }}
                  />
                  <div style={{ marginBottom: "20px" }}>
                    <FormControlLabel
                      sx={{ ml: 0 }}
                      labelPlacement="end"
                      label={
                        <Typography
                          sx={{ fontWeight: "500", fontSize: "14px", userSelect: "none" }}
                        >
                          顧客を新規作成して案件に紐づける
                        </Typography>
                      }
                      control={
                        <Checkbox
                          id="createNewClientCheckbox"
                          name="createNewClient"
                          sx={{ p: 0, pr: 1 }}
                          onChange={handleCheckCreateNewClient}
                          checked={isCreateNewClient}
                        />
                      }
                    />
                  </div>
                  {isCreateNewClient && (
                    <>
                      <CustomFormLabel
                        labelName="顧客名"
                        small
                        labelColor="primary"
                        required
                        requiredSize="14px"
                      />
                      <STextField
                        id="clientName"
                        name="clientName"
                        value={formState.project?.client?.name}
                        onChange={handleChangeClientForm}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Box>

        {errorMessage && (
          <Flash title="エラー" severity="error" message={errorMessage} sx={{ mt: "1rem" }} />
        )}
        <div ref={scrollBottomRef} />
      </CustomModal>
    </>
  );
};
