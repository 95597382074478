export interface PageInfo {
  page: number;
  pages: number;
  items: number;
  count: number;
}

export const initialPageInfo: PageInfo = {
  page: 1,
  pages: 1,
  items: 1,
  count: 1,
};
