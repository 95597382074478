import { ObjectKeys } from "types/objectKeys";
import { Brand } from "../brand";
import { PhotoResponseSnakeCase } from "./photo/photo.dto";
import { ProjectBillingId } from "./project_billing/project_billing.dto";
import { Building, BuildingId } from "../building/building.dto";
import { Client, ClientId } from "../client/client.dto";
import { PageInfo } from "../page_info/page_info.dto";
import {
  ProjectStatusType,
  ProjectStatusTypeId,
  initialProjectStatusType,
} from "../project_status_type/project_status_type.dto";
import { ProjectType, initProjectType, ProjectTypeId } from "../project_type/project_type.dto";
import { initialUser, User, UserId } from "../user/user.dto";

export type ProjectId = Brand<number, "ProjectId">;

// 案件一覧で利用
export interface ProjectCollection {
  id: ProjectId;
  code: string;
  name: string;
  address: string;
  address_second: string;
  estimate_user_names: string;
  client: Client;
  inquiry_number: string;
  inquired_by?: User;
  manager?: User;
  inquired_date?: Date;
  ordered_date?: Date;
  expected_complete_date?: Date;
  completed_date?: Date;
  sales_amount: number;
  sales_tax: number;
  is_no_bill: boolean;
  billing_condition: string;
  billing_precaution: string;
  building: Building | null;
  is_supported_by_maker: boolean;
  note: string;
  person_name: string;
  project_type: {
    id: ProjectTypeId;
    name: string;
  };
  project_status_type: {
    id: ProjectStatusTypeId;
    name: string;
    color_number: string;
  };
  updated_at: Date;
  todos: {
    tag_type: {
      value: string;
      value_i18n: string;
    };
    content: string;
    is_done: boolean;
  }[];
  project_billings: {
    id: ProjectBillingId;
    amount: number;
    tax: number;
  }[];
}

export interface Project extends ObjectKeys {
  // 共通
  id: ProjectId;
  client: Client | null;
  building: Building | null;
  billing_precaution: string;
  project_type: ProjectType;
  project_status_type: ProjectStatusType;
  requested_by_person_name: string;
  inquiry_number: string;
  code: string;
  name: string;
  postal_code: string;
  address: string;
  address_second: string;
  email: string;
  phone_number: string;
  phone_number_second: string;
  fax_number: string;
  note: string;
  report_content: string;
  inquired_date: string | null;
  ordered_date: string | null;
  expected_complete_date: string | null;
  completed_date: string | null;
  photo_info_v2: {
    first_three_photos: PhotoResponseSnakeCase[];
    total_count: number;
  };
  manager: User | null;
  inquired_by: User;
  is_manage_billing_on_another_system: boolean;
  is_supported_by_maker: boolean;
  is_no_bill: boolean;
  sales_amount: number;
  sales_tax: number;
  total_cost_amount: number;
  decided_estimates_total_amount: number;
  photo_url_key: string;
  person_name: string;
}

export interface ProjectIndexRequest {
  projectTypeIds?: ProjectTypeId[];
  projectStatusTypeIds?: ProjectStatusTypeId[];
  startInquiredDate?: string;
  endInquiredDate?: string;
  startOrderedDate?: string;
  endOrderedDate?: string;
  startCompletedDate?: string;
  endCompletedDate?: string;
  keyword?: string;
  billingConditions?: string[];
  sort?: string;
  direction?: "desc" | "asc";
  page?: number;
  rowsPerPage?: number;
}

export interface ProjectIndexResponse {
  data: ProjectCollection[];
  pagination: PageInfo;
}

export interface ProjectCreateRequest extends ObjectKeys {
  clientId: ClientId | null;
  buildingId: BuildingId | null;
  managerId: UserId | null;
  inquiredById: UserId | null;
  inquiryNumber: string;
  name: string;
  code: string;
  projectTypeId: ProjectTypeId;
  projectStatusTypeId: ProjectStatusTypeId;
  requester: string;
  requestedByPersonName: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  faxNumber: string;
  email: string;
  billingPrecaution: string;
  note: string;
  reportContent: string;
  inquiredDate: string;
  orderedDate: string;
  expectedCompleteDate: string;
  completedDate: string;
  isSupportedByMaker: boolean;
  isManageBillingOnAnotherSystem: boolean;
  isNoBill: boolean;
  salesAmount: number;
  personName: string;
}

export interface ProjectFormUpdateRequest {
  clientId: ClientId | null;
  buildingId: BuildingId | null;
  managerId: UserId | null;
  inquiredById: UserId | null;
  inquiryNumber: string;
  name: string;
  code: string;
  projectTypeId: ProjectTypeId;
  projectStatusTypeId: ProjectStatusTypeId;
  requester: string;
  requestedByPersonName: string;
  reportContent: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  faxNumber: string;
  email: string;
  billingPrecaution: string;
  note: string;
  inquiredDate: string;
  orderedDate: string;
  expectedCompleteDate: string;
  completedDate: string;
  isSupportedByMaker: boolean;
  isManageBillingOnAnotherSystem: boolean;
  isNoBill: boolean;
  salesAmount: number;
  tax: number;
  totalCostAmount: number;
  personName: string;
}

export interface ProjectUpdateRequest extends ObjectKeys {
  clientId?: ClientId | null;
  buildingId?: BuildingId | null;
  managerId?: UserId;
  inquiredById?: UserId;
  inquiryNumber?: string;
  name?: string;
  code?: string;
  projectTypeId?: ProjectTypeId;
  requester?: string;
  requestedByPersonName?: string;
  projectStatusTypeId?: ProjectStatusTypeId;
  postalCode?: string;
  address?: string;
  addressSecond?: string;
  phoneNumber?: string;
  phoneNumberSecond?: string;
  faxNumber?: string;
  email?: string;
  billingPrecaution?: string;
  note?: string;
  reportContent?: string;
  inquiredDate?: string;
  orderedDate?: string;
  expectedCompleteDate?: string;
  completedDate?: string;
  isSupportedByMaker?: boolean;
  isManageBillingOnAnotherSystem?: boolean;
  isNoBill?: boolean;
  salesAmount?: number;
  tax?: number;
  totalCostAmount?: number;
  personName?: string;
}

export interface ProjectDownloadRequest {
  projectTypeIds?: number[];
  projectStatusTypeIds?: ProjectStatusTypeId[];
  startInquiredDate?: string;
  endInquiredDate?: string;
  startOrderedDate?: string;
  endOrderedDate?: string;
  startCompletedDate?: string;
  endCompletedDate?: string;
  keyword?: string;
  billingConditions?: string[];
}

export interface RelatedProject {
  id: ProjectId;
  code: string;
  name: string;
  sales_amount: number;
  project_status_type: {
    id: ProjectStatusTypeId;
    name: string;
    color_number: string;
  };
  completed_date: string;
  manager: User;
  note: string;
}

export const initialProjectResponse: Project = {
  // 共通
  id: 0 as ProjectId,
  client: null,
  building: null,
  billing_precaution: "",
  project_status_type: initialProjectStatusType,
  requested_by_person_name: "",
  project_type: initProjectType,
  is_no_bill: false,
  inquiry_number: "",
  is_manage_billing_on_another_system: false,
  code: "",
  name: "",
  postal_code: "",
  address: "",
  address_second: "",
  email: "",
  phone_number: "",
  phone_number_second: "",
  fax_number: "",
  note: "",
  report_content: "",
  inquired_date: null,
  ordered_date: null,
  expected_complete_date: null,
  completed_date: null,
  photo_info_v2: {
    first_three_photos: [],
    total_count: 0,
  },
  attachment_types: [],
  manager: initialUser,
  inquired_by: initialUser,
  is_supported_by_maker: false,
  sales_amount: 0,
  sales_tax: 0,
  total_cost_amount: 0,
  decided_estimates_total_amount: 0,
  photo_url_key: "",
  person_name: "",
};

export const initialProjectRequest: ProjectUpdateRequest = {
  clientId: 0 as ClientId,
  buildingId: 0 as BuildingId,
  managerId: 0 as UserId,
  inquiredById: 0 as UserId,
  inquiryNumber: "",
  isManageBillingOnAnotherSystem: false,
  isNoBill: false,
  name: "",
  code: "",
  projectTypeId: 0 as ProjectTypeId,
  requester: "",
  requestedByPersonName: "",
  projectStatusTypeId: 0 as ProjectStatusTypeId,
  postalCode: "",
  address: "",
  addressSecond: "",
  phoneNumber: "",
  phoneNumberSecond: "",
  faxNumber: "",
  email: "",
  billingPrecaution: "",
  note: "",
  reportContent: "",
  inquiredDate: new Date().toString(),
  isSupportedByMaker: false,
  salesAmount: 0,
};

export interface ProjectFormHeaderState {
  name: string;
  projectStatusTypeId: ProjectStatusTypeId;
  clientId: ClientId | null;
  buildingId: BuildingId | null;
}

export interface ProjectFormIdentificationState {
  code: string;
  inquiryNumber: string;
  managerId: UserId | null;
  inquiredById: UserId | null;
  projectTypeId: ProjectTypeId;
}

export interface ProjectFormDatesState {
  inquiredDate: string;
  orderedDate: string;
  expectedCompleteDate: string;
  completedDate: string;
}

export interface ProjectFormSalesState {
  salesAmount: number;
  tax: number;
  totalCostAmount: number;
}

export interface ProjectFormNotesState {
  note: string;
  billingPrecaution: string;
}

export interface ProjectFormSiteState {
  personName: string;
  postalCode: string;
  address: string;
  addressSecond: string;
  phoneNumber: string;
  phoneNumberSecond: string;
  faxNumber: string;
  email: string;
}

export interface ProjectFormBillingState {
  isManageBillingOnAnotherSystem: boolean;
  isNoBill: boolean;
  isSupportedByMaker: boolean;
}

export interface ProjectFormReportContentsState {
  requester: string;
  requestedByPersonName: string;
  reportContent: string;
}

export const initialProjectFormHeaderState: ProjectFormHeaderState = {
  name: "",
  projectStatusTypeId: 0 as ProjectStatusTypeId,
  clientId: null,
  buildingId: null,
};

export const initialProjectFormIdentificationState: ProjectFormIdentificationState = {
  code: "",
  inquiryNumber: "",
  managerId: null,
  inquiredById: null,
  projectTypeId: 0 as ProjectTypeId,
};

export const initialProjectFormDatesState: ProjectFormDatesState = {
  inquiredDate: "",
  orderedDate: "",
  expectedCompleteDate: "",
  completedDate: "",
};

export const initialProjectFormSalesState: ProjectFormSalesState = {
  salesAmount: 0,
  tax: 0,
  totalCostAmount: 0,
};

export const initialProjectFormNotesState: ProjectFormNotesState = {
  note: "",
  billingPrecaution: "",
};

export const initialProjectFormSiteState: ProjectFormSiteState = {
  personName: "",
  postalCode: "",
  address: "",
  addressSecond: "",
  phoneNumber: "",
  phoneNumberSecond: "",
  faxNumber: "",
  email: "",
};

export const initialProjectFormBillingState: ProjectFormBillingState = {
  isManageBillingOnAnotherSystem: false,
  isNoBill: false,
  isSupportedByMaker: false,
};

export const initialProjectFormReportContentsState: ProjectFormReportContentsState = {
  requester: "",
  requestedByPersonName: "",
  reportContent: "",
};
