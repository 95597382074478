import { theme } from "extensions/theme";

export const styles = {
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    position: "sticky",
    top: 0,
    pt: "5rem",
    backgroundColor: theme.palette.grayScale[0],
    zIndex: "2",
    boxShadow: "0px 5px 5px -5px rgba(0,0,0,0.5)",
  },
  textField: {
    width: "30rem",
    "& .MuiInputBase-input": {
      fontWeight: "bold",
    },
  },
};
