import FaxIcon from "@mui/icons-material/Fax";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { CustomFormLabel } from "components/molecules/custom-form-label";
import { BuildingRequest } from "data-access/repositories/building/building.dto";
import { theme } from "extensions/theme";
import { styles } from "./styles";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClickSearchAddress: () => void;
  onClickSearchPostalCode: () => void;
  formState: BuildingRequest;
  addressErrorMessage: string;
  postalCodeErrorMessage: string;
}

export const BuildingSidebarInfo = (props: Props) => {
  const {
    onChange,
    onBlur,
    onClickSearchAddress,
    onClickSearchPostalCode,
    formState,
    addressErrorMessage,
    postalCodeErrorMessage,
  } = props;

  return (
    <>
      <CustomFormLabel labelName="基本情報" />
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          gap: "20px",
        }}
      >
        <div>
          <div style={{ display: "flex" }}>
            <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
              郵便番号
            </Typography>
            <Button
              onClick={onClickSearchAddress}
              disabled={!formState.postalCode}
              sx={{ py: 0, ml: "8px", fontSize: "14px" }}
            >
              住所を検索
            </Button>
          </div>
          <TextField
            id="postalCode"
            name="postalCode"
            value={formState.postalCode}
            inputProps={{ maxLength: 8 }}
            error={addressErrorMessage !== ""}
            helperText={addressErrorMessage}
            onChange={onChange}
            onBlur={onBlur}
            InputProps={{
              startAdornment: <InputAdornment position="start">〒</InputAdornment>,
            }}
            sx={{ ...styles.textField, width: "150px" }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ color: theme.palette.grayScale[700], fontSize: "14px" }}>
              都道府県・市区町村・番地
            </Typography>
            <Button
              sx={{ py: 0 }}
              onClick={onClickSearchPostalCode}
              disabled={formState.address === undefined || formState.address.length === 0}
            >
              郵便番号を検索
            </Button>
          </div>
          <TextField
            id="address"
            name="address"
            value={formState.address}
            error={postalCodeErrorMessage.length > 0}
            helperText={postalCodeErrorMessage}
            onChange={onChange}
            onBlur={onBlur}
            sx={{ ...styles.textField, width: "100%" }}
            placeholder="東京都渋谷区神泉町8-1"
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <div>
          <Typography sx={{ ...styles.typography }}>
            <PersonIcon sx={{ width: "18px" }} />
            先方担当者名
          </Typography>
          <TextField
            id="personName"
            name="personName"
            value={formState.personName || ""}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="現場 太郎"
            sx={{ ...styles.textField }}
          />
        </div>
        <div>
          <Typography sx={{ ...styles.typography }}>
            <PhoneIcon sx={{ width: "18px" }} />
            電話番号1
          </Typography>
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            value={formState.phoneNumber || ""}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="000-0000-0000"
            sx={{ ...styles.textField }}
          />
        </div>
        <div>
          <Typography sx={{ ...styles.typography }}>
            <PhoneIcon sx={{ width: "18px" }} />
            電話番号2
          </Typography>
          <TextField
            id="phoneNumberSecond"
            name="phoneNumberSecond"
            value={formState.phoneNumberSecond || ""}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="000-0000-0000"
            sx={{ ...styles.textField }}
          />
        </div>
        <div>
          <Typography sx={{ ...styles.typography }}>
            <FaxIcon sx={{ width: "18px" }} />
            FAX番号
          </Typography>
          <TextField
            id="faxNumber"
            name="faxNumber"
            value={formState.faxNumber || ""}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="000-0000-0000"
            sx={{ ...styles.textField }}
          />
        </div>
        <div>
          <Typography sx={{ ...styles.typography }}>
            <MailOutlineIcon sx={{ width: "18px" }} />
            メールアドレス
          </Typography>
          <TextField
            id="email"
            name="email"
            value={formState.email || ""}
            onChange={onChange}
            onBlur={onBlur}
            placeholder="***@gembahub.jp"
            sx={{ ...styles.textField, width: "230px" }}
          />
        </div>
      </div>
      <div>
        <Typography sx={{ ...styles.typography }}>概要・メモ</Typography>
        <TextField
          id="note"
          name="note"
          multiline
          value={formState.note || ""}
          onChange={onChange}
          onBlur={onBlur}
          placeholder="クリックして文章を入力"
          fullWidth
          minRows={3}
          sx={{
            "& .MuiInputBase-root": {
              padding: "8px 8px",
            },
          }}
        />
      </div>
    </>
  );
};
