import { ConfirmDialog } from "components/molecules/confirm-dialog";
import { BillingId } from "data-access/repositories/billing/billing.dto";
import { BillingInvoiceId } from "data-access/repositories/billing/invoice/invoice.dto";
import { billingSidebarOperations } from "store/billing-sidebar/operations";
import { selectBillingSidebar } from "store/billing-sidebar/slice";
import { billingTableOperations } from "store/billing-table/operations";
import { deleteConfirmDialogOperations } from "store/delete-confirm-dialog/operations";
import { selectDeleteConfirmDialog } from "store/delete-confirm-dialog/slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export const DeleteConfirmDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectDeleteConfirmDialog);
  const billingDetailState = useAppSelector(selectBillingSidebar);

  const handleYes = () => {
    dispatch(deleteConfirmDialogOperations.close());
    switch (state.object.type) {
      case "billing":
        dispatch(billingTableOperations.destroy(state.object.id as BillingId));
        break;
      case "invoice":
        dispatch(
          billingSidebarOperations.destroyInvoice(
            billingDetailState.billing.id,
            state.object.id as BillingInvoiceId,
          ),
        );
        break;
      case "billingWarranty":
        dispatch(
          billingSidebarOperations.destroyWarranty(
            billingDetailState.billing.id,
            state.object.id as number,
          ),
        );
        break;
    }
  };

  const handleNo = () => {
    dispatch(deleteConfirmDialogOperations.close());
  };

  return (
    <ConfirmDialog
      content="削除しますか？"
      yesButtonText="削除"
      handleNo={handleNo}
      handleYes={handleYes}
      isOpen={state.isOpen}
      yesButtonColor="error"
    />
  );
};
