import { useMemo, useState } from "react";
import { Close, ContentCopy } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { photoRepository as projectPhotoRepository } from "data-access/repositories/project/photo/photo.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { projectRepository } from "data-access/repositories/project/project.repository";
import { workReportPhotoRepository as workReportPhotoRepository } from "data-access/repositories/work_report/photo/photo.repository";
import { WorkReportId } from "data-access/repositories/work_report/work_report.dto";
import { workReportRepository } from "data-access/repositories/work_report/work_report.repository";
import { theme } from "extensions/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mainOperations } from "store/main/operations";
import { selectMain } from "store/main/slice";
import useSWR, { mutate } from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";

type ResourceType = "projects" | "work_reports";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: ProjectId | WorkReportId;
  resourceType: ResourceType;
  photoUrlKey: string;
}

export const ShareSettingDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const mainState = useAppSelector(selectMain);
  const [isChecked, setIsChecked] = useState<boolean>(props.photoUrlKey !== "");
  const [shareUrlKey, setShareUrlKey] = useState<string>(props.photoUrlKey);
  const [shareUrlTip, setShareUrlTip] = useState<string>("共有用URLをコピーします");
  const endPoint = `api/v1/${props.resourceType}`;

  const shareUrl = useMemo(() => {
    switch (props.resourceType) {
      case "projects":
        return `${window.location.origin}/photos/${shareUrlKey}`;
      case "work_reports":
        return `${window.location.origin}/work-reports/${shareUrlKey}/share`;
    }
  }, [window.location.origin, shareUrlKey]);

  const photoCountIndexKey = `/${endPoint}/${props.id}/photos/count_info`;
  const { data: photoCountInfo } = useSWR(photoCountIndexKey, () => {
    switch (props.resourceType) {
      case "projects":
        return projectPhotoRepository.countInfo(props.id as ProjectId);
      case "work_reports":
        return workReportPhotoRepository.countInfo(props.id as WorkReportId);
    }
  });

  const handleOpenUrlTip = () => {
    setShareUrlTip("共有用URLをコピーします");
  };

  const createPhotoUrlKey = async () => {
    try {
      let res: string = "";
      switch (props.resourceType) {
        case "projects":
          res = await projectRepository.createPhotoUrlKey(props.id as ProjectId);
          break;
        case "work_reports":
          res = await workReportRepository.createUrlKey(props.id as WorkReportId);
          break;
      }
      setShareUrlKey(res);
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const deletePhotoUrlKey = async () => {
    try {
      switch (props.resourceType) {
        case "projects":
          await projectRepository.deletePhotoUrlKey(props.id as ProjectId);
          break;
        case "work_reports":
          await workReportRepository.deleteUrlKey(props.id as WorkReportId);
          break;
      }
      setShareUrlKey("");
    } catch (error) {
      dispatch(mainOperations.updateErrorMessage(error.response.data.message));
    }
  };

  const updateParentComponent = () => {
    switch (props.resourceType) {
      case "projects":
        mutate(API_PATHS.getProject(props.id as ProjectId));
        break;
      case "work_reports":
        mutate(`/${endPoint}/${props.id}`);
        break;
    }
  };

  const handleSwitch = async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch(mainOperations.updateIsLoading(true));
    setIsChecked(checked);
    try {
      if (checked) {
        await createPhotoUrlKey();
      } else {
        await deletePhotoUrlKey();
      }
      await updateParentComponent();
    } catch (error) {
      handleReduxError(error, dispatch, "更新に失敗しました");
    } finally {
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  const copyShareUrlToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setShareUrlTip("コピーしました");
    } catch (error) {
      alert((error && error.message) || "コピーに失敗しました");
    }
  };

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        sx={{
          "& .MuiDialog-paper": { p: "8px 24px 20px", width: "360px", height: "270px" },
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontWeight: "500" }}>共有設定</p>
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </div>
        <Divider sx={{ mb: "14px" }} />
        <div style={{ marginBottom: "16px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>リンクで共有</p>
            {/* リンクされる枚数をここで表示 */}
            {isChecked && photoCountInfo && (
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: theme.palette.blue[500],
                  marginLeft: "10px",
                  marginTop: "15px",
                }}
              >
                {photoCountInfo.sharableCount} / {photoCountInfo.totalCount}枚 共有中
              </p>
            )}
            <p style={{ flexGrow: 1 }}></p>
            <Switch checked={isChecked} onChange={handleSwitch} disabled={mainState.isLoading} />
          </div>
          <p style={{ fontSize: "12px" }}>
            この案件に紐づく全ての写真を閲覧・ダウンロード可能なリンクを生成します。
          </p>
        </div>
        {shareUrlKey && (
          <TextField
            id="link"
            name="link"
            value={shareUrl}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end" sx={{ width: "30px" }}>
                  <Tooltip title={shareUrlTip} onOpen={handleOpenUrlTip} placement="top" arrow>
                    <Button
                      startIcon={<ContentCopy fontSize="small" />}
                      onClick={copyShareUrlToClipboard}
                      color="primary"
                      sx={{ p: 0, justifyContent: "end", display: "block", lineHeight: "1" }}
                    />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        )}
        <Backdrop
          sx={{ color: theme.palette.grayScale[0], zIndex: () => 99 }}
          open={mainState.isLoading}
          invisible
        >
          <CircularProgress />
        </Backdrop>
      </Dialog>
    </>
  );
};
