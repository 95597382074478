import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent, Box } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { CustomDataGrid } from "components/molecules/custom-data-grid";
import { ClientIndexResponse } from "data-access/repositories/client/client.dto";
import { initialPageInfo } from "data-access/repositories/page_info/page_info.dto";

interface Props {
  data: ClientIndexResponse | undefined;
  page: number;
  setPage: (e: number) => void;
  isLoading: boolean;
}

export const ClientTable = (props: Props) => {
  const navigate = useNavigate();

  // ページャーselect選択
  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    props.setPage(Number(event.target.value));
  };

  // ページャー選択(next, prev)
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.setPage(newPage + 1);
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/clients/${params.row.id}`);
  };

  const columns = useMemo(() => {
    const headers = [];
    headers.push({
      field: "name",
      headerName: "顧客名",
      minWidth: 300,
    });
    headers.push({
      field: "billing_person_section",
      headerName: "部署",
      minWidth: 300,
    });
    headers.push({
      field: "code",
      headerName: "会計ソフトコード",
      minWidth: 150,
    });
    headers.push({
      field: "billing_closing_date_type_i18n",
      headerName: "請求締日",
      minWidth: 100,
    });
    headers.push({
      field: "deposit",
      headerName: "入金日",
      minWidth: 150,
    });
    headers.push({
      field: "deposit_method_type_i18n",
      headerName: "入金方法",
      minWidth: 100,
    });
    return headers;
  }, []);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <CustomDataGrid
          columns={columns}
          rows={
            props.data?.data.map((v) => ({
              ...v,
              code: v.code || "-",
              billing_person_section: v.billing_person_section || "-",
              billing_closing_date_type_i18n:
                v.billing_closing_date_type_i18n !== "未設定"
                  ? v.billing_closing_date_type_i18n
                  : "-",
              deposit:
                (v.deposit_month_type_i18n !== "未設定" ? v.deposit_month_type_i18n : "-") +
                (v.deposit_date_type_i18n !== "未設定" ? v.deposit_date_type_i18n : "-"),
              deposit_method_type_i18n:
                v.deposit_method_type_i18n !== "未設定" ? v.deposit_method_type_i18n : "-",
            })) || []
          }
          loading={props.isLoading}
          pageInfo={props.data?.pagination || initialPageInfo}
          currentPage={props.page}
          handleSelect={handleSelect}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          tableHeight="80vh"
        />
      </Box>
    </>
  );
};
