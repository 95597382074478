import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Typography, Tooltip, styled, tooltipClasses, TooltipProps } from "@mui/material";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRenderCellParams, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { MultilineTooltip } from "components/atoms/multiline-tooltip";
import { TodoTagTypeLabel } from "components/label/todo-tag-type-label";
import { Layout } from "components/templates/layout";
import { getFilterItem } from "data-access/local_storage/table_filter/local_storage";
import {
  ProjectCollection,
  ProjectIndexRequest,
} from "data-access/repositories/project/project.dto";
import { ProjectTodo } from "data-access/repositories/project/todo/todo.dto";
import { theme } from "extensions/theme";
import { ProjectTable } from "features/project/components/table";
import { ProjectTableHeader } from "features/project/components/tableHeader";
import { calculateGrossProfitRate } from "utils/calculateGrossProfit";
import { DEFAULT_ROWS_PER_PAGE } from "utils/constant";
import { DATE_SLASH_FORMAT, DATE_TIME_SLASH_FORMAT, formatDateUtil } from "utils/formatDateUtil";
import { ProjectSidebar } from "../../features/project/components/sidebar";
type CSVRenderFunction<T> = (value: T, row: GridValidRowModel) => string;

export interface CSVGridColDef extends Omit<GridColDef, "field"> {
  field: string;
  hide?: boolean;
  csvRender?: CSVRenderFunction<any>;
}
const defaultHeaders: CSVGridColDef[] = [];
defaultHeaders.push({
  field: "code",
  headerName: "案件番号",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  disableReorder: true,
});
defaultHeaders.push({
  field: "name",
  headerName: "案件名",
  minWidth: 250,
  sortingOrder: ["desc", "asc", null],
  disableReorder: true,
});
defaultHeaders.push({
  field: "inquired_by_name",
  headerName: "受付担当者",
  minWidth: 110,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.inquired_by?.name}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.inquired_by?.name;
  },
});
// renderCellはProjectModalのdefaultHeadersの反映作業にて追加される
defaultHeaders.push({
  field: "project_status_type_name",
  headerName: "案件ステータス",
  minWidth: 150,
  align: "left",
  sortingOrder: ["desc", "asc", null],
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.project_status_type.name;
  },
});
defaultHeaders.push({
  field: "todos",
  headerName: "TODO",
  minWidth: 130,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<ProjectTodo[]>) => {
    if (!params.value) return;

    const undoneTodos = params.value.filter((todo: ProjectTodo) => !todo.is_done);

    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.grayScale[0],
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: "12px",
      },
    }));

    return (
      <>
        {undoneTodos.map((todo: ProjectTodo, index: number) => (
          <CustomTooltip key={index} title={todo.content} placement="bottom">
            <div>
              <TodoTagTypeLabel
                key={todo.id}
                tagType={todo.tag_type.value_i18n}
                sx={{ mr: "4px" }}
              />
            </div>
          </CustomTooltip>
        ))}
      </>
    );
  },
  renderHeader: (params: GridColumnHeaderParams) => (
    <Tooltip title="未完了のTODOの数でソートします" placement="top">
      <div style={{ textAlign: "center" }}>{params.colDef.headerName}</div>
    </Tooltip>
  ),
  csvRender: (todos: ProjectTodo[]) => {
    return todos.map((todo) => todo.tag_type.value_i18n).join(" ");
  },
});
defaultHeaders.push({
  field: "project_type_name",
  headerName: "案件タイプ",
  minWidth: 110,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.project_type.name}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.project_type.name;
  },
});
defaultHeaders.push({
  field: "is_no_bill",
  headerName: "請求しない",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => (
    <>{params.row.is_no_bill ? <CheckCircleOutlineIcon fontSize="small" /> : ""}</>
  ),
});
defaultHeaders.push({
  field: "billing_condition",
  headerName: "請求状況",
  minWidth: 100,
  sortable: false,
});
defaultHeaders.push({
  field: "client_name",
  headerName: "顧客名",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.client?.name}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.client?.name;
  },
});
defaultHeaders.push({
  field: "client_phone_number",
  headerName: "顧客電話番号",
  minWidth: 150,
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.client?.phone_number}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.client?.phone_number;
  },
});
defaultHeaders.push({
  field: "building_name",
  headerName: "物件名",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.building ? params.row.building.name : ""}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.building ? row.building.name : "";
  },
});
defaultHeaders.push({
  field: "inquiry_number",
  headerName: "先方注文番号",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
});
defaultHeaders.push({
  field: "inquired_date",
  headerName: "受付日",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => (value ? formatDateUtil(value, DATE_SLASH_FORMAT) : ""),
});
defaultHeaders.push({
  field: "ordered_date",
  headerName: "受注日",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => (value ? formatDateUtil(value, DATE_SLASH_FORMAT) : ""),
});
defaultHeaders.push({
  field: "estimate_user_names",
  headerName: "見積担当者",
  minWidth: 110,
  sortable: false,
});
defaultHeaders.push({
  field: "manager_name",
  headerName: "現場責任者",
  minWidth: 130,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.manager?.name}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.manager?.name;
  },
});
defaultHeaders.push({
  field: "expected_complete_date",
  headerName: "完了予定日",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => (value ? formatDateUtil(value, DATE_SLASH_FORMAT) : ""),
});
defaultHeaders.push({
  field: "is_supported_by_maker",
  headerName: "メーカー対応",
  minWidth: 130,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => (
    <>
      {params.row.is_supported_by_maker ? (
        <>
          <CheckCircleOutlineIcon fontSize="small" />
          <Typography fontSize="0.8rem">メーカー対応</Typography>
        </>
      ) : (
        "ー"
      )}
    </>
  ),
  csvRender: (value: boolean) => (value ? "メーカー対応" : "ー"),
});
defaultHeaders.push({
  field: "completed_date",
  headerName: "完了日",
  minWidth: 100,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => (value ? formatDateUtil(value, DATE_SLASH_FORMAT) : ""),
});
defaultHeaders.push({
  field: "sales_amount",
  headerName: "売上",
  type: "number",
  minWidth: 120,
  sortable: false,
  editable: true,
  renderCell: (params: GridRenderCellParams) => {
    const salesAmount = params.row.sales_amount;
    return (
      <Typography style={{ lineHeight: "inherit" }}>{salesAmount?.toLocaleString()}</Typography>
    );
  },
});
const determineColor = (value: number) => {
  if (value < 0) {
    return theme.palette.error.main;
  } else {
    return theme.palette.text.primary;
  }
};
defaultHeaders.push({
  field: "gross_profit_amount",
  headerName: "粗利",
  type: "number",
  minWidth: 120,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => {
    const grossProfitAmount = params.row.sales_amount - params.row.total_cost_amount;
    const color = determineColor(grossProfitAmount);
    return (
      <Typography style={{ color, lineHeight: "inherit" }}>
        {grossProfitAmount.toLocaleString()}
      </Typography>
    );
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return (row.sales_amount - row.total_cost_amount).toString();
  },
});
defaultHeaders.push({
  field: "gross_profit_rate",
  headerName: "粗利率",
  type: "number",
  minWidth: 120,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => {
    const grossProfitRate = calculateGrossProfitRate(
      params.row.sales_amount,
      params.row.total_cost_amount,
    );
    const color = determineColor(Number(grossProfitRate));
    return <Typography style={{ color }}>{grossProfitRate}%</Typography>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return calculateGrossProfitRate(row.sales_amount, row.total_cost_amount).toString() + "%";
  },
});
defaultHeaders.push({
  field: "total_cost_amount",
  headerName: "原価",
  type: "number",
  minWidth: 120,
  sortable: false,
  renderCell: (params: GridRenderCellParams) => {
    return (
      <Typography style={{ lineHeight: "inherit" }}>
        {params.row.total_cost_amount.toLocaleString()}
      </Typography>
    );
  },
});
defaultHeaders.push({
  field: "person_name",
  headerName: "先方担当者名",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams) => {
    return <>{params.row.person_name ? params.row.person_name : ""}</>;
  },
  csvRender: (_: number, row: GridValidRowModel) => {
    return row.person_name ? row.person_name : "";
  },
});
defaultHeaders.push({
  field: "combinedAddress",
  headerName: "住所",
  minWidth: 200,
  sortable: false,
  renderCell: (params: GridRenderCellParams<ProjectCollection>) => {
    return `${params.row.address || ""}${params.row.address_second ? " " + params.row.address_second : ""}`.trim();
  },
  csvRender: (_, row) => {
    return `${row.address || ""}${row.address_second ? " " + row.address_second : ""}`.trim();
  },
});
defaultHeaders.push({
  field: "note",
  headerName: "概要・メモ",
  minWidth: 250,
  sortable: false,
  renderCell: (params: GridRenderCellParams<ProjectCollection>) => (
    <MultilineTooltip params={params} />
  ),
  csvRender: (value: string) => {
    // テキストフィールド内にダブルクォートが含まれる場合改行されてしまうため
    // それを2つのダブルクォートに置き換えることでテキストの一部であることを示す
    return `"${value.replace(/"/g, '""')}"`;
  },
});
defaultHeaders.push({
  field: "billing_precaution",
  headerName: "請求に関する注意事項",
  minWidth: 250,
  sortable: false,
  renderCell: (params: GridRenderCellParams<ProjectCollection>) => (
    <MultilineTooltip params={params} />
  ),
  csvRender: (value: string) => {
    return `"${value.replace(/"/g, '""')}"`;
  },
});
defaultHeaders.push({
  field: "updated_at",
  headerName: "最終更新日時",
  minWidth: 150,
  sortingOrder: ["desc", "asc", null],
  renderCell: (params: GridRenderCellParams<Date>) => (
    <Typography sx={{ fontSize: "0.875rem" }}>
      {params.value ? formatDateUtil(params.value, DATE_TIME_SLASH_FORMAT) : ""}
    </Typography>
  ),
  csvRender: (value: Date) => formatDateUtil(value, DATE_TIME_SLASH_FORMAT),
});

export default function Projects() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const sortStorageKeyName = "projectTableColumnSort";

  const searchQuery = (page?: number) => {
    const filterItem = getFilterItem("project");

    const query: ProjectIndexRequest = {
      projectTypeIds: filterItem.projectTypeIds,
      projectStatusTypeIds: filterItem.projectStatusTypeIds,
      billingConditions: filterItem.billingConditions,
      page: page ?? currentPage,
      rowsPerPage: rowsPerPage,
    };
    if (filterItem.keyword !== "") {
      query.keyword = filterItem.keyword;
    }
    if (filterItem.inquiredDate.range.startDate) {
      query.startInquiredDate = formatDateUtil(
        filterItem.inquiredDate.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItem.inquiredDate.range.endDate) {
      query.endInquiredDate = formatDateUtil(filterItem.inquiredDate.range.endDate, "yyyy-MM-dd");
    }
    if (filterItem.orderedDate.range.startDate) {
      query.startOrderedDate = formatDateUtil(filterItem.orderedDate.range.startDate, "yyyy-MM-dd");
    }
    if (filterItem.orderedDate.range.endDate) {
      query.endOrderedDate = formatDateUtil(filterItem.orderedDate.range.endDate, "yyyy-MM-dd");
    }
    if (filterItem.completedDate.range.startDate) {
      query.startCompletedDate = formatDateUtil(
        filterItem.completedDate.range.startDate,
        "yyyy-MM-dd",
      );
    }
    if (filterItem.completedDate.range.endDate) {
      query.endCompletedDate = formatDateUtil(filterItem.completedDate.range.endDate, "yyyy-MM-dd");
    }

    const localStorageSortItem = localStorage.getItem(sortStorageKeyName);
    if (localStorageSortItem !== null) {
      const sortItem: { field: string; sort: "desc" | "asc" } = JSON.parse(localStorageSortItem);
      query.sort = sortItem.field;
      query.direction = sortItem.sort;
    }
    return query;
  };

  return (
    <Layout>
      <Box>
        <ProjectTableHeader />
        <Box sx={{ mt: 2 }}>
          <ProjectTable
            defaultHeaders={defaultHeaders}
            setIsSidebarOpen={setIsOpen}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
          />
        </Box>

        <ProjectSidebar
          isSidebarOpen={isOpen}
          setIsSidebarOpen={setIsOpen}
          currentPage={currentPage}
        />
      </Box>
    </Layout>
  );
}
