import { Box } from "@mui/material";
import { TitleLabel } from "components/label/title-label";
import { FileDropzone } from "components/molecules/file-dropzone";
import { estimateRepository } from "data-access/repositories/project/estimate/estimate.repository";
import { ProjectId } from "data-access/repositories/project/project.dto";
import { EstimateCard } from "features/project/components/sidebar/estimate-content/card";
import { useBlockOperationsWhileUploading } from "hooks/useBlockOperationsWhileUploading";
import { useAppDispatch } from "store/hooks";
import { mainOperations } from "store/main/operations";
import useSWR from "swr";
import { API_PATHS } from "utils/apiPaths";
import { handleReduxError } from "utils/errorHandler";

interface Props {
  projectId?: ProjectId;
  isCreateNew: boolean;
}
export const ProjectSidebarEstimateContent = (props: Props) => {
  const dispatch = useAppDispatch();

  const { blockOperationsWhileUpload, releaseBlockOperationsWhileUpload } =
    useBlockOperationsWhileUploading();

  const { data: estimates, mutate } = useSWR(
    props.projectId ? API_PATHS.getProjectEstimates(props.projectId) : null,
    () => estimateRepository.index(props.projectId as ProjectId),
  );

  const handleUpload = async (files: File[]) => {
    if (!props.projectId) return;
    blockOperationsWhileUpload();
    dispatch(mainOperations.updateIsLoading(true));
    try {
      await estimateRepository.create(props.projectId, files);
      dispatch(mainOperations.updateSuccessMessage("見積書をアップロードしました"));
      mutate();
    } catch (error) {
      handleReduxError(error, dispatch, "見積書のアップロードに失敗しました");
    } finally {
      releaseBlockOperationsWhileUpload();
      dispatch(mainOperations.updateIsLoading(false));
    }
  };

  return (
    <>
      <TitleLabel title="見積書" />
      <div>
        <FileDropzone
          onDrop={handleUpload}
          isDisable={props.isCreateNew}
          moduleId={props.projectId}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          {props.projectId &&
            estimates &&
            estimates.map((estimate) => (
              <EstimateCard
                key={estimate.id}
                projectId={props.projectId as ProjectId}
                estimate={estimate}
              />
            ))}
        </Box>
      </div>
    </>
  );
};
